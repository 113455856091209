<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="$t('care.marketing_batchs.create_task')"
      :visible="visible"
      @close="onClose"
      append-to-body
      width="1000px">
    <el-form
        :model="form"
       :label-width="formLabelWidth"
       style="max-width: 800px"
        @submit.native.prevent
        ref="form"
    >
      <el-alert
          style="margin: 0 180px 10px 120px;"
          :title="$t('care.marketing_batchs.create_task_tiptitle')"
          type="warning"
          :description="$t('care.marketing_batchs.create_task_tips')"
          show-icon
          close-text="OK">
      </el-alert>
      <el-form-item required :label="$t('care.marketing_batchs.batch_name')" prop="name">
        <div class="formval">
          <el-input  v-model="form.name" clearable></el-input>
        </div>
      </el-form-item>

      <el-form-item required :label="$t('care.marketing_batchs.messenger_id')" prop="messenger_id">
        <div class="formval">
          <el-select v-model="form.messenger_id" placeholder="messenger" @change="onMessengerChange">
            <el-option
                v-for="item in messengerOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="tips">{{$t('care.marketing_batchs.messenger_tips')}}</div>
      </el-form-item>

      <el-form-item required v-if="form.messenger_id!==null" :label="$t('care.marketing_batchs.tpl_id')" prop="tpl_id">
        <div class="formval">
          <el-select @change="onSelectTpl" v-model="form.tpl_id" :placeholder="$t('care.workbenchPage.choice_tpl')">
            <el-option
                v-for="item in tplOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <div style="padding-left: 100px;" v-if="form.tpl_id!==null" :label="$t('care.marketing_batchs.tpl_params')">
        <el-divider content-position="center">{{ $t('care.message_page.preview_tpl') }}</el-divider>
        <div style="display: flex;flex-direction: row;justify-content: flex-start;align-items: flex-start;">
          <div style="width: 300px;max-height: 400px; overflow-y: auto;" class="hidden_scrollbar">
            <TplCard :key="form.tpl_id" v-if="form.tpl_id"
                     :params="JSON.stringify(form.tpl_params)"
                     :inner="true"
                     :visible="tplCardVisible"
                     :tpl="selectedTpl()" />
          </div>
          <div>
            <el-form-item
                required
                v-for="mapping in getTplParamsMappings(form.tpl_id)"
                :key="mapping.key"
                :prop="'tpl_params.'+mapping.key"
                :label="mapping.label">
              <div class="formval">
                <el-input  v-model="form.tpl_params[mapping.key]"></el-input>
              </div>
            </el-form-item>
          </div>
        </div>


        <el-divider></el-divider>
      </div>

      <el-form-item required :label="$t('care.marketing_batchs.recips')"  prop="recips">
        <div class="formval">
          <el-input
              type="textarea"
              :autosize="{ minRows: 8, maxRows: 12}"
              v-model="form.recips"
              :placeholder="recips_placeholder"
          ></el-input>
        </div>
        <div class="tips">{{$t('care.marketing_batchs.recips_tips')}}</div>
      </el-form-item>

      <el-form-item required :label="$t('care.marketing_batchs.time_type')"  prop="time_type">
        <div class="formval">
          <el-radio-group v-model="form.time_type">
            <el-radio-button :label="0">{{$t('care.marketing_batchs.time_type_0')}}</el-radio-button>
            <el-radio-button :label="1">{{$t('care.marketing_batchs.time_type_1')}}</el-radio-button>
          </el-radio-group>
        </div>
      </el-form-item>

      <el-form-item required v-if="form.time_type===1" :label="$t('care.marketing_batchs.plan_at')"  prop="plan_at">
        <div class="formval">
          <el-date-picker
              v-model="form.plan_at"
              type="datetime"
              :picker-options="pickerOptions"
              placeholder="Select date">
          </el-date-picker>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">{{$t('care.messenger.cancel')}}</el-button>
      <el-button :loading="addLoading" type="primary" @click="save">{{$t('care.messenger.sure')}}</el-button>
    </div>
  </el-dialog>


</template>

<script>


import dayjs from "dayjs";
import {random} from "lodash/number";
import {getGroup} from "@/libs/apis/group";
import {getMessengerTpls} from "@/libs/apis/messenger";
import TplCard from "@/pages/admin/components/TplCard.vue";
import {postMarketingBatch} from "@/libs/apis/marketingbatch";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
  name: "MarketingBatchForm",
  props: [
    "visible",
    "group_id",
    "care_uid",
  ],
  components:{
    TplCard,
  },
  data(){
    const thisDate=new Date(dayjs().format('YYYY-MM-DD 00:00')).getTime();
    return {
      formLabelWidth:'180px',
      formItemLoading:false,
      form:{
        name:this.newName(),
        tpl_id:null,
        messenger_id:null,
        tpl_params:{},
        recips:null,
        time_type:0,
        plan_at:null,
      },
      spinning:false,
      addLoading:false,
      tplCardVisible:false,
      messengerOpts:[],
      tplOptions:[],
      tpls:[],
      tplDic:{},
      recips_placeholder:'85277777777\n85288888888\n85299999999\nother...\n',
      pickerOptions:{
        disabledDate(time) {
          return time.getTime() < thisDate;
        },
      },

    };
  },
  watch:{
  },
  mounted() {
    this.initMessengerOpts();
  },
  methods:{
    validatePhoneNumber(phoneNumber) {
      if(!phoneNumber.startsWith('+')){
        phoneNumber='+'+phoneNumber;
      }
        // 解析号码并自动检测国家/地区
        const phoneInfo = parsePhoneNumberFromString(phoneNumber);
        // 检查是否有效且为国际格式
        return (phoneInfo?.isValid() && phoneInfo?.isPossible());
    },
    selectedTpl(){
      if(this.form.tpl_id&&this.form.tpl_id in this.tplDic){
        return this.tplDic[this.form.tpl_id].data;
      }else{
        return  {};
      }
    },
    getTplParamsMappings(tpl_id){
      const tpl=this.tplDic[tpl_id];
      const mappings=tpl.parm_mappings;
      if(mappings&&mappings!=='null'){
        return JSON.parse(mappings);
      }else if(mappings==='null'){
        let rs=[];
        if(tpl.body_parms){
          const body_parms= JSON.parse(tpl.body_parms);
          rs=rs.concat(body_parms.map(item=>{
            return {
              key:item,
              type:'string',
              label:item,
            };
          }))
        }
        if(tpl.header_parms){
          const header_parms= JSON.parse(tpl.header_parms);
          rs=rs.concat(header_parms.map(item=>{
            return {
              key:item,
              type:'string',
              label:item,
            };
          }))
        }
        return  [...new Set(rs)];
      }else{
        this.$message.error('模板异常，请联系管理员同步一次模板');
        return [];
      }

    },
    async onSelectTpl(tpl_id){
      this.tplCardVisible=true;
    },
    async onMessengerChange(messenger_id){
      this.form.tpl_id=null;
      this.loadTplOpts(messenger_id);
    },
    async loadTplOpts(messenger_id){
      const tpls=await this.loadTpls(messenger_id);
      this.tpls=tpls;
      this.tplOptions = tpls.map(item => {
        this.tplDic[item.id] = item;
        let name=item.alias?item.alias:item.name;
        return {value: item.id, label: `${name}(${item.language})`}
      });
    },
    async loadTpls(messenger_id) {
      const cache_key=`care_load_tpls:${messenger_id}`;
      const cache= sessionStorage.getItem(cache_key);
      let tpls=[];
      if(cache){
        tpls=JSON.parse(cache);
        let that=this;
        setTimeout(async function () {
          tpls = await getMessengerTpls(messenger_id);
          sessionStorage.setItem(cache_key,JSON.stringify(tpls));
        },5000);
      }else{
        tpls = await getMessengerTpls(messenger_id);
        sessionStorage.setItem(cache_key,JSON.stringify(tpls));
      }
      return tpls;
    },
    newName(){
      const day= dayjs().format('YYMMDDHHmmss')+random(99,999);
      return `M${day}`;
    },
    async save(){
       const that=this;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
         let form= JSON.parse(JSON.stringify(this.form));
         if(form.time_type===0){
           form.plan_at=null;
         }else{
           form.plan_at=dayjs(form.plan_at).format('YYYY-MM-DD HH:mm:ss');
         }
         if(Object.keys(form.tpl_params).length === 0){
           form.tpl_params=[];
         }
          form.recips = form.recips.split('\n').map(item=>item.replace(/[^\d]/g, '')).filter(item=>item!=='');
          console.log(form)
          for (let i = 0; i < form.recips.length; i++) {
            const phone=form.recips[i];
             if(!that.validatePhoneNumber(phone)){
               this.$message.error(`wrong phone: ${phone}`)
               return ;
             }
          }
         try {
           this.addLoading=true;
           await postMarketingBatch(this.group_id,{
             ...form,
             care_uid:this.care_uid
           });
           this.$message.success('success');
           this.$emit("onSuccess");
           this.onClose();
         }catch (e) {
           this.$message.error(e.data.message);
         }finally {
           this.addLoading=false;
         }

        } else {
          return false;
        }
      });


    },
    async initMessengerOpts(){
     const group= await getGroup(this.group_id);
     const whatsappMessengers=group.messengers.filter(item=>item.provider==='whatsapp');
     this.messengerOpts= whatsappMessengers.map(item=>{
        return {
          value:item.id,
          label:item.name,
        }
      })
    },
    onClose(){
      this.$emit("onClose");
    },
  },
}
</script>

<style scoped lang="less">
/* 适用于 Firefox */
.hidden_scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* 适用于 Chrome, Safari, Edge 等基于 WebKit 的浏览器 */
.hidden_scrollbar::-webkit-scrollbar {
  display: none;
}
.tips{
  font-size: small;
  line-height: 24px;
  color: #888888;
}
</style>