
const OrderStateResultMixin = {
    data(){
        return {
            order_status_result_visible:false,
            order_renewal_visible:true,
            order_status_title:this.$t('care.buy_page.title'),
        };
    },
    methods:{

    },
}
export default OrderStateResultMixin;
