import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";

export const postWelcomeWordV2 = (group_id:string,care_id:string,{word,enabled,cycle_type,day_of_week,start_at,end_at}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/v2welcome_words/${care_id}/words`,
        method: 'post',
        data:{word,enabled,cycle_type,day_of_week,start_at,end_at}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const putWelcomeWordV2 = (group_id:string,care_id:string,id,{word,enabled,cycle_type,day_of_week,start_at,end_at}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/v2welcome_words/${care_id}/words/${id}`,
        method: 'put',
        data:{word,enabled,cycle_type,day_of_week,start_at,end_at}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteWelcomeWordV2 = (group_id:string,care_id:string,id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/v2welcome_words/${care_id}/words/${id}`,
        method: 'delete'
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getWelcomeWords= (group_id:string,care_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/v2welcome_words/${care_id}/words`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});