<template>
  <el-drawer
      :wrapperClosable="false"
      :title="$t('care.tpls.user_promotion_switch_manage')"
      :visible.sync="visible"
      @close="onClose"
      append-to-body
      size="95%"
  >
    <div style="height:100%;padding: 30px">
      <div class="list" style="height:100%;">
        <div class="header">
          <div style="font-weight: bolder;margin-right: 10px;font-size: 14px;">{{$t('care.tpls.user_promotion_switch_tips')}}</div>
          <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="reload" ></el-button>
        </div>
        <div class="content" style="height:calc( 100% - 40px);">
          <el-table
              class="table"
              v-loading="spinning"
              :data="data"
              height="calc( 100vh - 190px)"
              highlight-current-row
              row-key="id"
          >
            <el-table-column
                prop="recip"
                :label="$t('care.marketing_batchs.recips')"
            ></el-table-column>
            <el-table-column
                prop="name"
                :label="$t('care.name')"
            ></el-table-column>

            <el-table-column
                prop="off_at"
                :label="$t('care.off_at')"
            ></el-table-column>

            <el-table-column
                width="130"
                :label="$t('care.ops')"
            >
              <template slot-scope="scope">
                <div class="options">
                  <el-popconfirm
                      :title="$t('care.deleteUserPromotionConfirm')"
                      :confirm-button-text="$t('care.messenger.sure')"
                      :cancel-button-text="$t('care.messenger.cancel')"
                      @confirm="handleCancel(scope.$index, scope.row)"
                  >
                    <el-button
                        slot="reference"
                        :title="$t('care.delete')"
                        size="mini"
                        type="danger">{{$t('care.delete')}}</el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>

          </el-table>
          <el-pagination
              small
              layout="prev, pager, next"
              :current-page.sync="page"
              :page-size="pageSize"
              @current-change="pageChange"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onClose()">{{$t('care.messenger.close')}}</el-button>
    </div>
  </el-drawer>
</template>

<script>
import {cancelMarketingBatch, getMarketingBatchs} from "@/libs/apis/marketingbatch";
import {deleteUserPromotionSwitch, getUserPromotionSwitchs} from "@/libs/apis/user_promotion_switch";


export default {
  name: "UserPromotionSwitchDrawer",
  props: [
    "group_id",
    "visible",
  ],
  components:{
  },
  data(){
    return {
      formLabelWidth:'120px',
      formItemLoading:false,
      formVisible:false,
      form:{},
      spinning:false,
      data:[],
      pageSize:10,
      page:1,
      total:0,
      filter:{
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods:{
    onSearch(){
      this.reload();
    },
    reload(){
      this.page=1;
      this.total=0;
      this.getList();
    },
    onClose(){
      this.$emit("onClose");
    },
    async handleCancel(index, row){
      await deleteUserPromotionSwitch(this.group_id,row.id);
      this.$message.success('success');
      this.getList();
    },
    async getList(){
      this.spinning=true;
      const {data,total}=await getUserPromotionSwitchs(this.group_id,{
        page:{
          number:this.page,
          size:this.pageSize,
        },
        filter:this.filter,
        include:[]
      });
      this.data=data.map(item=>{
        item.recip=item.uid?.replace('whatsapp_','')
        return item;
      });
      this.total=total;
      this.spinning=false;
    },
    pageChange(page){
      this.page=page;
      this.getList();
    },
  },
}
</script>

<style scoped lang="less">
.total{
  cursor: pointer;
  .el-icon-view{
    display: none;
  }
  &:hover{
    .el-icon-view{
      display: inline-block;
    }
  }
}
.options{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
/* 适用于 Firefox */
.hidden_scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* 适用于 Chrome, Safari, Edge 等基于 WebKit 的浏览器 */
.hidden_scrollbar::-webkit-scrollbar {
  display: none;
}
</style>