import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";
import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";

export const getUserPromotionSwitchs=(group_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    const str= parse(query)
    request({
        url: `${admin_api}groups/${group_id}/user_promotion_switchs${str}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const deleteUserPromotionSwitch=(group_id:string,id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/user_promotion_switchs/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});