<template>
  <el-drawer
      append-to-body
      :wrapperClosable="wrapperClosable"
      :title="$t('care.order.renewal')"
      size="80%"
      :visible.sync="centervisible"
      @close="onClose"
      :with-header="false">
    <div v-if="!payUrl" class="content">

      <div>
        <div slot="title" class="tip">
          <i class="el-icon-bank-card"></i>
          <div>{{title}}</div>
        </div>
        <div class="package_list_warp">
          <div class="package_list">
            <div class="package">
              <div class="head"> </div>
              <div class="line">{{$t('care.buy_page.seats')}}</div>
              <div class="line">{{$t('care.buy_page.bots')}}</div>
              <div class="line">{{$t('care.buy_page.mediaMessage')}}</div>
              <div class="line">{{$t('care.buy_page.channel')}}</div>
              <div class="line">{{$t('care.buy_page.employees')}}</div>
              <div class="line">{{$t('care.buy_page.messageStorage')}}</div>
            </div>
            <div class="package">
              <div class="head">
                <div class="title">{{$t('care.buy_page.package.free')}}</div>
                <div class="price">HKD 0.00 ／{{$t('care.buy_page.package.month')}}</div>
                <div class="btn">
                </div>
                <div class="desc">{{$t('care.buy_page.package.free_desc')}}</div>
              </div>
              <div class="line">2</div>
              <div class="line">X</div>
              <div class="line">✔</div>
              <div class="line">{{$t('care.buy_page.onlyWeb')}}</div>
              <div class="line">X</div>
              <div class="line">✔</div>
            </div>
            <div class="package">
              <div class="head">
                <div class="title">{{$t('care.buy_page.package.marketing')}}</div>
                <div class="price">HKD 458.00 ／{{$t('care.buy_page.package.month')}}</div>
                <div class="btn">
                  <el-button @click="onBuy('care_seat')" type="primary" size="mini">{{$t('care.buy_page.package.buy')}}</el-button>
                </div>
                <div class="desc">{{$t('care.buy_page.package.marketing_desc')}}</div>
              </div>
              <div class="line">10</div>
              <div class="line">-</div>
              <div class="line">✔</div>
              <div class="line">{{$t('care.buy_page.allChannel')}}</div>
              <div class="line">✔</div>
              <div class="line">✔</div>
            </div>
            <div class="package">
              <div class="head">
                <div class="title">{{$t('care.buy_page.package.bot')}}</div>
                <div class="price">HKD 1833.00 ／{{$t('care.buy_page.package.month')}}</div>
                <div class="btn">
                  <el-button @click="onBuy('care_bot')" type="primary" size="mini">{{$t('care.buy_page.package.buy')}}</el-button>
                </div>
                <div class="desc">{{$t('care.buy_page.package.bot_desc')}}</div>
              </div>
              <div class="line">-</div>
              <div class="line">✔</div>
              <div class="line">-</div>
              <div class="line">-</div>
              <div class="line">-</div>
              <div class="line">-</div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 30px">
        <div><h3>{{$t('care.order.allOrder')}}</h3></div>
        <el-table
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            max-height="250">
<!--          <el-table-column-->
<!--              prop="order_id"-->
<!--              label="訂單">-->
<!--          </el-table-column>-->
          <el-table-column
              prop="product_type"
              :label="$t('care.order.cambo')">
            <template slot-scope="scope">
              <el-tag :type="scope.row.product_type === 'care_seat' ? 'primary' : 'success'">
                {{getProductByType(scope.row.product_type)}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="cycle_type"
              :label="$t('care.order.cycle')">
            <template slot-scope="scope">
              <div>
                {{getCycleType(scope.row.cycle_type)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="available_days"
              :label="$t('care.order.available')">
            <template slot-scope="scope">
              <div :class="[getAvailableDaysClass(scope.row.available_days)]">
                {{getAvailableDays(scope.row.available_days)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="pay_bill_date"
              :label="$t('care.order.pay_bill_date')">
          </el-table-column>
          <el-table-column
              fixed="right"
              :label="$t('care.order.opts')">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="renewalRow(scope.row)"
                  type="primary"
                  size="medium">
                {{$t('care.order.renewal')}}
              </el-button>
            </template>
          </el-table-column>
          <template slot="append">
            <div class="foot">
              <div>{{$t('care.order.seats_consume')}} <strong :class="seat_used-seat_total>-1?'lacking':''">{{seat_used}}/{{seat_total}}</strong></div>
            </div>
          </template>
        </el-table>
      </div>
    </div>
    <div v-else class="pay_url">
      <div class="head">
        <span @click="onClosePay"><i class="el-icon-back"></i> {{$t('care.order.back')}}</span>
      </div>
      <iframe :src="payUrl" frameborder="0"></iframe>
    </div>
  </el-drawer>
</template>

<script>
// 订单续费
import {getOrders, openPayPage} from "@/libs/apis/order";
import {getAuthor} from "@/libs/utils/author";
import qs from "qs";

export default {
  name: "OrderRenewal",
  props: {
    visible: {
      type: Boolean,
    },
    group_id: {
      type: [Number, String],
    },
    title: {
      type: String,
      default:'续费',
    },
    show_close: {
      type: Boolean,
      default: true // 默认为 true
    }
  },
  data(){
    const {visible}=this
    return {
      centervisible:visible,
      payUrl:null,
      loading:false,
      wrapperClosable:this.show_close,
      tableData:[],
      openInfo:null,
      seat_used:0,
      seat_total:0,
      bot_order:null,
      loadingText:'充值查询中...',
    }
  },
  watch:{
    visible(newVal){
      this.centervisible=newVal;
    },
  },
  emits: ["onClose"],
  mounted() {
    this.getList();
  },
  methods:{
    getAvailableDays(available_days){
      if(available_days<=0){
        return '過期';
      }
      if(available_days<=7){
        return `${available_days}天後過期`;
      }
      return `${available_days}天`;
    },
    getAvailableDaysClass(available_days){
      if(available_days<=0){
        return 'expired';
      }
      if(available_days<=7){
        return `pressing`;
      }
      return '';
    },
    onClosePay(){
      let that=this;
      this.payUrl = null;
      if(this.openInfo){
        const loading = this.$loading({
          lock: true,
          text: this.loadingText,
          background: 'rgba(255, 255, 255, 0.7)'
        });
        setTimeout(async function () {
          await that.getList();
          loading.close();
          if(that.show_close)
          {
            that.wrapperClosable = true;
          }
        },6000);
      }
    },
    getProductByType(product_type){
      let str='YME對話營銷工具楼器人';
      if(product_type==='care_seat')
      {
        str='YME對話營銷工具';
      }
      return str;
    },
    getCycleType(cycle_type){
      let str='月付';
      if(cycle_type==='annual')
      {
        str='年付';
      } else if(cycle_type==='two_years'){
        str='2年';
      }
      return str;
    },
    async getList(){
      this.loading=true;
      const rs= await getOrders(this.group_id);
      this.tableData=rs.orders;
      this.seat_used=rs.seat_used;
      this.seat_total=rs.seat_total;
      this.bot_order=rs.bot_order;
      this.loading=false;

    },
    onClose(){
      this.$emit("onClose");
    },
    getPayUrl: function () {
      return 'https://care-ui-care-order-ui.apps.v8eq.hk.topkee.top';
    },
    async createPayUrl(product_type, pandQuery, pay_url) {
      this.wrapperClosable = false;
      const rs = await openPayPage(this.group_id, product_type);
      const jwtToken = getAuthor();
      this.openInfo = rs;
      const callbackParams = JSON.stringify(rs);
      const success_url = window.location.href;
      const param = {
        jwtToken,
        callbackParams,
        product_type,
        success_url,
      };
      let query = qs.stringify(param);
      query += pandQuery;
      this.payUrl = `${pay_url}?${query}`;
    },
    async renewalRow(row){

      let cycle=1;
      if(row.cycle_type==='annual')
      {
        cycle=12;
      } else if(row.cycle_type==='two_years'){
        cycle=24;
      }
      const product_type=row.product_type;
      const pay_url =`${this.getPayUrl()}/order/${row.order_id}/debit_note/new`;
      const pandQuery=`&cycle=${cycle}`;

      await this.createPayUrl(product_type, pandQuery, pay_url);
    },
    async onBuy(product_type){
      const pay_url =`${this.getPayUrl()}/order`;
      this.createPayUrl(product_type, '', pay_url)

    },
  },
}
</script>

<style scoped lang="less">
.content{
  padding: 30px;
  -moz-user-select:none; /* Firefox私有属性 */
  -webkit-user-select:none; /* WebKit内核私有属性 */
  -ms-user-select:none; /* IE私有属性(IE10及以后) */
  -khtml-user-select:none; /* KHTML内核私有属性 */
  -o-user-select:none; /* Opera私有属性 */
  user-select:none; /* CSS3属性 */
  .foot{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    line-height: 50px;
    .lacking{
      color:  rgb(220,54,46);
    }
  }
  .expired{
    color: rgb(220,54,46);
  }
  .pressing{
    color: rgb(232,110,48);
  }
}
.pay_url{
  width: 100%;
  height: 100%;
  iframe{
    width: 100%;
    height: 100%;
  }
  .head{
    padding: 0 60px;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-size: 18px;
    font-weight: bolder;
    cursor: pointer;
  }
}

.tip{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: rgb(232,110,48);
  div,i{
    font-size: 22px;
    margin-left: 10px;
    font-weight: bolder;
  }
}
.package_list_warp{
  padding: 30px 30px 70px 30px;
}
.package_list{
  font-family: normal;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  &:first-child{
    border-left: 1px solid #eee;
  }
  .package{
    flex: 1;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    &:hover{
      background-color: rgb(247,247,247);
    }
    .head{
      padding:0 10px;
      height: 200px;
      border-bottom: 1px solid #eee;
      margin-top: 10px;
      .title{
        font-weight: bolder;
        font-size: 17px;
        line-height: 30px;
        min-height: 30px;
      }
      .price{
        font-weight: bolder;
        line-height: 30px;
        min-height: 30px;
      }
      .btn{
        margin: 10px auto;
        min-height: 28px;
      }
    }
    .line{
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #eee;
    }
  }
}
</style>