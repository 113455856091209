
<template>
  <div>
    <div class="carelist">
      <div class="header">
        <el-button size="mini" plain type="primary" @click="()=>this.$router.back()">{{$t("care.back")}}</el-button>
        <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="getList()"></el-button>
<!--        <el-button size="mini" plain type="primary" icon="el-icon-link"  @click="handleCustomLink()">{{$t("care.copyCustom")}}</el-button>-->
        <el-button size="mini" plain type="primary" icon="el-icon-plus"  @click="handleAdd()">{{$t("care.addCare")}}</el-button>
        <el-button size="mini" plain type="primary" icon="el-icon-setting"  @click="handlesetting()"></el-button>
        <el-button size="mini" plain type="primary" icon="el-icon-bank-card"  @click="order_status_result_visible=true">{{ $t('care.order.renewal') }}</el-button>
        <div class="search">
          <span>{{$t("care.search_title")}}：</span>
          <div>
            <el-select @change="onSearch" clearable size="small" style="width: 100px" v-model="search.receive_able" :placeholder="$t('care.value_str.select')">
              <el-option :label="$t('care.value_str.on')" :value="1"></el-option>
              <el-option :label="$t('care.value_str.off')" :value="0"></el-option>
            </el-select>
          </div>
          <div>
            <el-input @keyup.enter.native="onSearch" size="small"  :placeholder="$t('care.search_title')" v-model="search.name" class="input-with-select">
              <el-button @click="onSearch" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>

      </div>
      <div class="content">
        <el-dialog
            :title="$t('care.deleteConfirm')"
            :visible.sync="visible"
            append-to-body>
          <div slot="footer" class="dialog-footer">
            <el-button @click="visible = false">{{$t("care.cancel")}}</el-button>
            <el-button type="danger"  :loading="deleteLoading"  @click="handleOk">{{$t("care.delete")}}</el-button>
          </div>
        </el-dialog>
        <el-empty :description="$t('care.notSetCare')" v-if="hadGetlist&&tableData.length===0"></el-empty>
        <el-table
            v-else
            v-loading="spinning"
            class="table"
            row-key="id"
            :data="tableData"
            :height="tableHeight"
            highlight-current-row
        >
          <!--          <el-table-column-->
          <!--              prop="group_name"-->
          <!--              :label="$t('care.group')"-->
          <!--          >-->
          <!--          </el-table-column>-->
          <el-table-column
              prop="name"
              :label="$t('care.care')"
          >
            <template slot-scope="scope">

              <div class="avatar">
                <a-avatar icon="user" :src="scope.row.avatar" />
                <a-tag style="margin-left: 8px;" color="blue">{{scope.row.name}}</a-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="tags"
              label="tags"
          >
            <template slot-scope="scope">

              <div class="avatar" v-if="scope.row.tags">
                <a-tag style="margin-left: 8px;" color="blue" v-for="tag in scope.row.tags" :key="JSON.stringify(tag)">{{tag.type}}: {{tag.name}}</a-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="online"
              :label="$t('care.onlinestate.title')"
          >
            <template slot-scope="scope">
              <a-tag v-if="scope.row.online===1" color="blue">{{$t('care.onlinestate.on')}}</a-tag>
              <a-tag v-else >{{$t('care.onlinestate.off')}}</a-tag>
            </template>
          </el-table-column>

          <el-table-column
              prop="receive_able"
              :label="$t('care.receive_able')"
          >
            <template slot-scope="scope">
              <a-tag v-if="scope.row.receive_able===1" color="blue">{{$t('care.value_str.on')}}</a-tag>
              <a-tag v-else >{{$t('care.value_str.off')}}</a-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="created_at"
              :label="$t('care.created_at')"
          >
          </el-table-column>
          <el-table-column
              :label="$t('care.ops')"
          >
            <template slot-scope="scope">
              <div class="options">
                <div>
                  <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="handleDelete(scope.$index, scope.row)"></el-button>
                </div>
                <div>
                  <el-button
                      size="mini"
                      type="primary"
                      icon="el-icon-edit"
                      @click="editCare(scope.row)"></el-button>
                </div>
                <div>
                  <el-button
                      size="mini"
                      type="primary" plain
                      icon="el-icon-phone-outline"
                      @click="handleLink(scope.$index, scope.row)">{{$t("care.workbench")}}</el-button>
                </div>
<!--                <div>-->
<!--                  <el-button-->
<!--                      size="mini"-->
<!--                      type="primary" plain-->
<!--                      icon="el-icon-document-copy"-->
<!--                      @click="handleUserList(scope.$index, scope.row)">指定对话</el-button>-->
<!--                </div>-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            small
            layout="prev, pager, next"
            :current-page.sync="page"
            :page-size="pageSize"
            @current-change="pageChange"
            :total="total">
        </el-pagination>
      </div>
<!--      <input v-model="copy_txt" type="text"  id="copy_dom" v-show="copying">-->
    </div>
    <GroupSetting v-if="showGroupSetting" :edit-row="group" :edit-visible="groupEditVisible" @onClose="groupEditVisible=false" @onChangeEditRow="onChangeEditRow"></GroupSetting>
<!--    <el-dialog-->
<!--        :title="$t('care.careform.add')"-->
<!--        :visible.sync="addCareVisible"-->
<!--        append-to-body>-->
<!--      <el-form :model="careForm"  :label-width="formLabelWidth" style="max-width: 800px">-->
<!--        <el-form-item :label="$t('care.careform.name')" prop="name">-->
<!--          <div class="formval">-->
<!--            <el-input  v-model="careForm.name"></el-input>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('care.careform.avatar')" prop="avatar">-->
<!--          <div class="formval">-->
<!--            <el-upload-->
<!--                :class="['avatar-uploader',careForm.avatar?'avatar-uploader-ok':'']"-->
<!--                action="string"-->
<!--                :show-file-list="false"-->
<!--                :before-upload="onBeforeUploadImage"-->
<!--                :http-request="upload">-->
<!--              <img v-if="careForm.avatar" :src="careForm.avatar" class="avatar">-->
<!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--            </el-upload>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('care.receive_able')" prop="receive_able">-->
<!--          <div class="formval">-->
<!--            <el-switch-->
<!--                :active-value=1-->
<!--                :inactive-value=0-->
<!--                v-model="careForm.receive_able">-->
<!--            </el-switch>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item v-if="careForm.uid" :label="$t('care.careform.bot_label')" prop="receive_able">-->
<!--          <div class="formval" style="color: dodgerblue;">-->
<!--            <div  @click="onEditBot('reply')" style="cursor: pointer;display: flex;align-items: center;">-->
<!--              <a-icon style="font-size: 1.5em;margin-right: 5px;" :component="botSvg"  />-->
<!--              <span>{{$t('care.botform.role_label_reply')}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="addCareVisible = false">{{$t('care.messenger.cancel')}}</el-button>-->
<!--        <el-button :loading="addCareLoading" type="primary" @click="saveCareForm">{{$t('care.messenger.sure')}}</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->

    <el-dialog
        title="指定对话"
        :visible.sync="showUserList"
        append-to-body>
      <el-form :model="userForm"  :label-width="formLabelWidth" style="max-width: 500px">
        <el-form-item label="手机号或标识" prop="uid">
          <div class="formval">
            <el-input  v-model="userForm.uid"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="渠道" prop="provider" v-if="group.messengers">
          <div class="formval" >
            <el-select size="small" style="width: 200px" v-model="userForm.provider" placeholder="provider">
              <el-option v-for="messenger in  group.messengers" :key="messenger.id" :label="messenger.name" :value="messenger.name"></el-option>
            </el-select>
          </div>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showUserList = false">{{$t('care.messenger.cancel')}}</el-button>
        <el-button type="primary" @click="onConnectUser">{{$t('care.messenger.sure')}}</el-button>
      </div>
    </el-dialog>
<!--    <BotForm v-if="botFormVisible" :visible="botFormVisible"  @onClose="onCloseBotForm" :group_id="group_id" :uid="careForm.uid" ></BotForm>-->
    <CareForm v-if="addCareVisible" :show_receive_able="true" :visible="addCareVisible"  @onClose="onCloseCareForm" :group_id="group_id" :row="careForm" ></CareForm>
    <OrderRenewal v-if="order_status_result_visible" @onClose="order_status_result_visible=false" :group_id="group_id" :visible="order_status_result_visible" :title="order_status_title"></OrderRenewal>
<!--    <OrderRenewal  v-if="order_renewal_visible" @onClose="order_renewal_visible=false" :group_id="group_id" :visible="order_renewal_visible" ></OrderRenewal>-->
  </div>

</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import {deleteGroup, getGroup, getGroupByAdmin, getGroups} from "@/libs/apis/group";
import {deleteGroupCare, getGroupCares} from "@/libs/apis/groupcare";
import careMixin from "@/mixins/careMixin";
import clientMixin from "@/mixins/clientMixin";
import linkMixin from "@/mixins/linkMixin";
import GroupSetting from "@/pages/admin/components/GroupSetting.vue";
import axios from "axios";
import {postCare, putCare, saveCare} from "@/libs/apis/care";
import BotForm from "@/pages/admin/components/BotForm.vue";
import CareForm from "@/pages/admin/components/CareForm.vue";
import BotSvg from "@/components/svgs/BotSvg";
import orderStateResultMixin from "../../../mixins/orderStateResultMixin";
import OrderStatusResult from "@/pages/admin/components/OrderStatusResult.vue";
import OrderRenewal from "@/pages/admin/components/OrderRenewal.vue";
import {code_need_pay,code_need_renewal} from "../../../config/customCode";
import {checkCareAsset} from "@/libs/apis/order";

export default{
	data(){
		return {
      botSvg:BotSvg,
      addCareVisible:false,
      deleteLoading:false,
      visible:false,
      spinning:false,
      hadGetlist:false,
      tableHeight:document.documentElement.clientHeight - 100,
      tableData:[],
      deleteRow:{},
      groupEditVisible:false,
      showGroupSetting:false,
      addCareLoading:false,
      formLabelWidth:'120px',
      group:{},
      careForm:{
        id:null,
        uid:null,
        name:null,
        avatar:null,
        receive_able:0,
      },
      search:{
        name:null,
        receive_able:null,
      },
      page:1,
      total:0,
      pageSize:10,
      botFormVisible:false,
      selectRow:null,
      showUserList:false,
      userForm:{
        uid:null,
        provider:null,
      },
		};
	},
	mounted: function(){
    this.$nextTick(function () {
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight = document.documentElement.clientHeight - 100;
      }
    });
    this.getList();
    let that=this;
    getGroupByAdmin(this.group_id).then((item)=>{
      item.emails=item.emails?item.emails.map(email=>email.email):[];
      item.colors=item.colors?JSON.parse(item.colors):{
        rgba:{ r:75,g:172,b:255,a:0.92 }
      }
      that.group=item
    });
	},
	components: {
    GroupSetting,
    BotForm,
    OrderStatusResult,
    OrderRenewal,
    CareForm,
  },
  computed: {
  },
	methods: {
    // onCloseBotForm(){
    //   this.botFormVisible=false;
    // },
    onCloseCareForm(){
      this.addCareVisible=false;
      this.getList();
    },
    // async onEditBot(){
    //   this.botFormVisible=true;
    // },
    onSearch(){
      this.page=1;
      this.getList();
    },
    pageChange(page){
      this.page=page;
      this.getList();
    },
    editCare(row){
      console.log(row)
      this.careForm=row;
      this.addCareVisible=true;
    },
    // async saveCareForm(){
    //   if(!this.careForm.name) {
    //     this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
    //     return;
    //   }
    //   try {
    //     this.addCareLoading=true;
    //     if(this.careForm.id){
    //       await putCare(this.group_id,this.careForm.id,this.careForm.name,this.careForm.avatar,this.careForm.receive_able);
    //     }else{
    //       await postCare(this.group_id,this.careForm.name,this.careForm.avatar,this.careForm.receive_able);
    //     }
    //     this.getList();
    //     this.addCareLoading=false;
    //     this.addCareVisible=false;
    //   }catch (e) {
    //     this.addCareLoading=false;
    //     if(e.code===code_need_pay||e.code===code_need_renewal){
    //       this.order_status_result_visible=true;
    //       this.order_status_title=e.message;
    //     }
    //     this.$message.error(e.message);
    //     throw e;
    //   }
    //
    // },
    // onBeforeUploadImage(file) {
    //   return true;
    // },
    // upload(param){
    //   console.log(param)
    //   const formData = new FormData()
    //   formData.append('file[]', param.file)
    //   // 没有什么参数
    //   let args={
    //     'acl':'public-read',
    //   };
    //   for(let key  in args){
    //     formData.append(key,args[key]);
    //   }
    //   const sign=this.makeSignature(args,'as2d4f5g6h7j8gfzcvbrgj8')
    //   formData.append('sign', sign)
    //   axios.post('https://static.toptopone.com/v2/common/upload',formData).then(rs=>{
    //     this.careForm.avatar=rs.data.data[0].signed_url;
    //   }).catch(err=>{
    //     message.error(this.$t('care.uploadFail'))
    //   });
    // },
    handlesetting(){
      this.showGroupSetting=true;
      this.groupEditVisible=true;
    },
    onChangeEditRow(row){
       this.group=row;
    },
    async handleOk(){
      try {

        this.deleteLoading=true;
        await deleteGroupCare(this.group_id,this.deleteRow.id)
        this.deleteLoading=false;
        this.visible=false;
        message.success(this.$t('care.opsSuccess'));
        this.getList();
      }catch (e) {
        message.error(e);
      }
    },
    async getList(){
      this.spinning=true;
      const map=new Map();
      map.set('users.name',this.search.name);
      map.set('receive_able',this.search.receive_able);
      const rs=await getGroupCares(this.group_id,{
        filter:map,
        append:['name','avatar','group_name'],
        include:['tags','welcome_word'],
        page:{
          number:this.page,
          size:this.pageSize,
        }
      });
      this.tableData= rs.data;
      this.total= rs.total;
      this.spinning=false;
      this.hadGetlist=true;
    },
    async handleAdd(){
      try {
        await checkCareAsset(this.group_id);
      }catch (e){
        if(e.code===code_need_pay||e.code===code_need_renewal){
          this.order_status_result_visible=true;
          this.order_status_title=e.message;
        }
        await this.$message.error(e.message);
        return;
      }
      this.careForm={
        id:null,
        name:null,
        avatar:null,
        receive_able:0,
        welcomeWord:null,
      };
      this.addCareVisible=true;
    },
    handleCustomLink(){
      const url= this.createCustomLink(this.group_id);
      this.postMsg('custom_link',JSON.stringify({
        url,
      }))
      this.copy(url);
    },
    handleLink(index,row){
      const url= this.createLink(this.group_id,row.uid);
      window.open(url);
		},
    handleUserList(index,row){
      this.selectRow=row;
      this.showUserList=true;
    },
    onConnectUser(){
      if(this.userForm.uid && this.userForm.provider){
        this.showUserList=false;
        const url =  this.createLink(this.group_id,this.selectRow.uid,this.userForm.uid,this.userForm.provider);
        window.open(url);
      }
    },
    handleCopyLink(index,row){
      const url= this.createLink(this.group_id,row.uid);
      this.postMsg('care_link',JSON.stringify({
        url,
      }))
      this.copy(url);
    },
    handleDelete(index,row){
      this.visible=true;
      this.deleteRow=row;
    },
	},
  mixins:[careMixin,clientMixin,linkMixin,orderStateResultMixin]
};
</script>
