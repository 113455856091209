<template>
  <el-drawer
      :wrapperClosable="false"
      :title="$t('care.marketing_batchs.detail_task')"
      :visible.sync="visible"
      @close="onClose"
      append-to-body
      size="90%"
  >

    <div style="padding: 10px;">
      <el-skeleton v-if="!batch" :rows="18" />
      <el-tabs  v-else v-model="activeName" @tab-click="getDetails">
        <el-tab-pane :label="$t('care.marketing_batchs.task_details')" name="first">
          <el-form

              :model="form"
              :label-width="formLabelWidth"
              style="max-width: 800px;margin-bottom: 30px;"
              @submit.native.prevent
              ref="form"
          >
            <el-form-item :label="$t('care.marketing_batchs.batch_name')">
              <div class="formval">
                <span>{{batch.name}}</span>
              </div>
            </el-form-item>

            <el-form-item :label="$t('care.marketing_batchs.messenger_id')">
              <div class="formval">
                <span>{{batch.messenger?batch.messenger.name:'unknow'}}</span>
              </div>
            </el-form-item>


            <!--          <el-form-item :label="$t('care.marketing_batchs.recips')"  >-->
            <!--            -->
            <!--          </el-form-item>-->

            <el-form-item :label="$t('care.marketing_batchs.time_type')"  prop="time_type">
              <div class="formval">
                <span>{{$t(`care.marketing_batchs.time_type_${batch.time_type}`)}}</span>

              </div>
            </el-form-item>

            <el-form-item v-if="batch.time_type===1" :label="$t('care.marketing_batchs.plan_at')">
              <div class="formval">
                <span>{{batch.plan_at}}</span>
              </div>
            </el-form-item>
            <el-form-item :label="$t('care.marketing_batchs.created_at')">
              <div class="formval">
                <span>{{batch.created_at}}</span>
              </div>
            </el-form-item>
            <el-form-item :label="$t('care.marketing_batchs.begin_at')">
              <div class="formval">
                <span>{{batch.begin_at}}</span>
              </div>
            </el-form-item>
            <el-form-item :label="$t('care.marketing_batchs.end_at')">
              <div class="formval">
                <span>{{batch.end_at}}</span>
              </div>
            </el-form-item>
            <el-form-item :label="$t('care.marketing_batchs.tpl_id')">
              <div class="formval">
                <span>{{batch.tpl?batch.tpl.name:'unknow'}}</span>
              </div>
            </el-form-item>

            <div style="padding-left: 100px;"
                 v-if="batch.tpl!==null"
                 :label="$t('care.marketing_batchs.tpl_params')">
              <el-divider content-position="center">{{ $t('care.message_page.preview_tpl') }}</el-divider>
              <div style="display: flex;flex-direction: row;justify-content: flex-start;align-items: flex-start;">
                <div style="width: 300px;max-height: 400px; overflow-y: auto;" class="hidden_scrollbar">
                  <TplCard :key="batch.tpl_id" v-if="batch.tpl_id"
                           :params="batch.tpl_params"
                           :inner="true"
                           :visible="true"
                           :tpl="batch.tpl.data" />
                </div>
                <div>
                  <el-form-item
                      v-for="mapping in tplparams"
                      :key="mapping.label"
                      :label="mapping.label">
                    <div class="formval">
                      {{mapping.value}}
                    </div>
                  </el-form-item>
                </div>
              </div>
            </div>

          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('care.marketing_batchs.recips_details')" name="second">
          <div class="content" style="height:calc( 100% - 40px);">
            <el-table
                class="table"
                v-loading="loading"
                :data="batch.recips"
                height="calc( 100vh - 190px)"
                highlight-current-row
                row-key="id"
            >

              <el-table-column
                  prop="recipient"
                  width="180"
                  :label="$t('care.marketing_batchs.recip')"
              ></el-table-column>

              <el-table-column
                  prop="status"
                  width="160"
                  :label="$t('care.marketing_batchs.status')"
              >
                <template slot-scope="scope">
                  {{$t(`care.marketing_batchs.recip_statu_${scope.row.status}`)}}
                </template>

              </el-table-column>
              <el-table-column
                  prop="reason"
                  :label="$t('care.marketing_batchs.reason')"
              ></el-table-column>
              <el-table-column
                  prop="reply_at"
                  width="180"
                  :label="$t('care.marketing_batchs.reply_at')"
              ></el-table-column>
              <el-table-column
                  prop="reply_detail"
                  :label="$t('care.marketing_batchs.reply_detail')"
              ></el-table-column>
              <el-table-column
                  width="130"
                  :label="$t('care.ops')"
              >
                <template slot-scope="scope">
                  <div class="options">

                    <el-button v-if="[0,-1,-2].indexOf(scope.row.status)===-1" style="margin-bottom: 5px;" @click="goConnect(scope.row)" type="primary" size="mini">
                      {{$t('care.marketing_batchs.go_connect')}}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
<!--            <el-pagination-->
<!--                small-->
<!--                layout="prev, pager, next"-->
<!--                :current-page.sync="page"-->
<!--                :page-size="pageSize"-->
<!--                @current-change="pageChange"-->
<!--                :total="total">-->
<!--            </el-pagination>-->
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>

import TplCard from "@/pages/admin/components/TplCard.vue";
import {getMarketingBatchDetails} from "@/libs/apis/marketingbatch";

export default {
  name: "MarketingBatchDetail",
  props: [
    "visible",
    "group_id",
    "row",
  ],
  components:{
    TplCard,
  },
  data(){
    return {
      loading:false,
      formLabelWidth:'180px',
      form:{ },
      batch:null,
      activeName:'first',
      pageSize:10,
      page:1,
      total:this.row.recips.length,
    };
  },
  computed:{
    tplparams(){
      let rs=[];
      if(this.row.tpl_params){
        let parm_mappings=[];
        if(this.row.tpl.parm_mappings&&this.row.tpl.parm_mappings!=='null'){
          parm_mappings= JSON.parse(this.row.tpl.parm_mappings);
        }
        try {
          const obj= JSON.parse(this.row.tpl_params);
          if(Array.isArray(obj) && obj.length === 0) {
            return rs;
          }
          Reflect.ownKeys(obj).forEach(key => {
            const find= parm_mappings.find(item=>item.key==key);
            let label=key;
            if(find){
              label=find.label;
            }
            rs.push({
              label:label+'：',
              value:obj[key],
            })
          });
        }catch (e) {
          console.log(e)
        }
      }

      return rs;
    },
  },
  watch:{
  },
  mounted() {
    this.getDetails();
  },
  methods:{
    goConnect(row){
      window.postMessage({
        type:'go_connect',
        data:{
          connect: {
             ...row.connect,
             connect_id:row.connect_id
          }

        }
      },window.location.origin);
    },
    pageChange(page){
      this.page=page;
    },
    async getDetails(){
      try {
        this.loading=true;
        this.batch=await getMarketingBatchDetails(this.group_id,this.row.id);

      }finally {
        this.loading=false;
      }


    },
    onClose(){
      this.$emit("onClose");
    },
  },
}
</script>

<style scoped lang="less">
/* 适用于 Firefox */
.hidden_scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* 适用于 Chrome, Safari, Edge 等基于 WebKit 的浏览器 */
.hidden_scrollbar::-webkit-scrollbar {
  display: none;
}
.tips{
  font-size: small;
  line-height: 24px;
  color: #888888;
}
</style>