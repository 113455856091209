
<template>
   <a-spin :spinning="!inited" tip="Loading...">
      <template v-if="careInvites.length">
        <div class="cares_warp">
          <div class="cares_inner" >
            <strong>{{$t('care.care_invites.title')}}：</strong>
            <div class="cares_list">
              <div @click="onChoiceCare(care)" :key="care.id" v-for="care in careInvites" class="item">{{care.instanze_name}}——{{$t('care.groupId')}} {{care.group_id}}</div>
            </div>
          </div>
        </div>
      </template>
     <template v-if="!is_manager">
       <div style="width: 500px; margin: 200px auto">
         <el-alert
           title="無權限"
           type="warning"
           description="您不屬於此資產組,如有需要，請通知此資產組的管理員邀請你"
           show-icon>
       </el-alert>
       </div>
     </template>
     <template v-else>
       <GroupSetting  v-if="editRow.id" :edit-row="editRow" :edit-visible="editVisible" @onClose="oncloseSetting" @onChangeEditRow="onChangeEditRow"></GroupSetting>

       <div v-show="inited">
         <template v-if="instanzed">

           <div class="admin" >

             <div class="header">
               <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="getList()"></el-button>
               <el-button size="mini"  plain type="primary" icon="el-icon-plus"  @click="handleAdd()">{{$t("care.addGroup")}}</el-button>
               <el-button size="mini"  plain type="primary" icon="el-icon-s-grid"  @click="handleShowService()">{{$t("care.otherservice.manager")}}</el-button>

             </div>
             <div class="content">
               <el-dialog
                   :title="$t('care.deleteConfirm')"
                   :visible.sync="visible"
                   append-to-body>
                 <el-form :label-width="formLabelWidth" style="max-width: 800px">
                   <el-form-item :label="$t('care.delete_group_confirm')" prop="business_id">
                     <div class="formval">
                       <el-input  v-model="delete_group_id"></el-input>
                     </div>
                   </el-form-item>
                 </el-form>
                 <div slot="footer" class="dialog-footer">
                   <el-button @click="visible = false">{{$t("care.cancel")}}</el-button>
                   <el-button type="danger"  :loading="deleteLoading"  @click="handleOk">{{$t("care.delete")}}</el-button>
                 </div>
               </el-dialog>
               <el-empty v-if="hadGetlist&&tableData.length===0"></el-empty>
               <el-table
                   v-else
                   class="table"
                   v-loading="spinning"
                   :data="tableData"
                   :height="tableHeight"
                   highlight-current-row
               >
                 <el-table-column
                     prop="id"
                     :label="$t('care.groupId')"
                     width="200"
                 >
                   <template slot-scope="scope">
                     <div class="avatar">
                       {{scope.row.id}}
                       <a-tag @click="handleLink(scope.$index, scope.row)">{{$t("care.copy")}}</a-tag>

                     </div>
                   </template>
                 </el-table-column>
                 <el-table-column
                     prop="use_for"
                     :label="$t('care.use_for')"
                     width="200"
                 >
                 </el-table-column>
                 <el-table-column
                     prop="created_at"
                     :label="$t('care.created_at')"
                 >
                 </el-table-column>
                 <el-table-column
                     :label="$t('care.ops')"
                 >
                   <template slot-scope="scope">
                     <div class="options">
                       <div>
                         <el-button
                             :title="$t('care.deleteGroup')"
                             size="mini"
                             type="danger" plain
                             icon="el-icon-delete"
                             @click="handleDelete(scope.$index, scope.row)"></el-button>
                       </div>
                       <div>
                         <el-button
                             title="setting"
                             size="mini"
                             icon="el-icon-setting"
                             @click="handleSetting(scope.$index, scope.row)">{{$t('care.messenger.group_setting')}}</el-button>
                       </div>
                       <div>
                         <el-button
                             :title="$t('care.manageCare')"
                             size="mini"
                             type="primary" plain
                             icon="el-icon-user"
                             @click="handleEdit(scope.$index, scope.row)">{{$t('care.virtual_care')}}</el-button>
                       </div>
                       <div v-if="invitation_able">
                         <el-button
                             :title="$t('care.manageInvitation')"
                             size="mini"
                             type="primary" plain
                             icon="el-icon-share"
                             @click="handleInvitation(scope.$index, scope.row)">{{$t('care.email_invitation')}}</el-button>
                       </div>
<!--                       <div v-if="scope.row.messengers.find(item=>item.provider==='whatsapp')">-->
<!--                         <el-button-->
<!--                             size="mini"-->
<!--                             type="primary" plain-->
<!--                             @click="handleTpl(scope.row.messengers.find(item=>item.provider==='whatsapp'))">模板</el-button>-->
<!--                       </div>-->
                     </div>
                   </template>
                 </el-table-column>
               </el-table>
             </div>
           </div>
           <!--            <input v-model="copy_txt" type="text"  id="copy_dom" v-show="copying">-->
         </template>
         <template v-else>
           <div class="init">
             <el-button :loading="instanzeing" size="lager"  plain type="primary" icon="el-icon-s-promotion"  @click="instanze()">
               {{$t('care.instanze.enable')}}
             </el-button>
           </div>
         </template>
       </div>
       <el-dialog
           :title="$t('care.otherservice.manager')"
           :visible.sync="showService"
           append-to-body>
          <div class="service_list">
            <el-card shadow="hover"  v-for="service in services" :key="service.name">
              <div class="service">
                <div class="name">{{service.name}}</div>
                <div class="status_warp">
                  <i>{{$t('care.otherservice.status_title')}}：</i>
                  <span class="status">{{service.subscribe===1?$t('care.otherservice.subscribe_ed'):$t('care.otherservice.subscribe_un')}}</span>
                </div>
                <div class="link" v-if="service.subscribe===1">
                  <el-button size="mini" :loading="service.loading_go_admin"  type="primary" @click="onGoAdmin(service)">{{$t('care.otherservice.go_admin')}}</el-button>
                </div>
                <div class="link" v-else>
                  <el-button  type="primary" plain size="mini" :loading="service.loading_subscribe" v-if="service.subscribe!==1" @click="onSubscribe(service)">{{$t('care.otherservice.subscribe')}}</el-button>
                </div>
              </div>
            </el-card>
          </div>

         <div slot="footer" class="dialog-footer">
           <el-button type="primary" @click="showService=false">{{$t('care.messenger.close')}}</el-button>
         </div>
       </el-dialog>
     </template>

   </a-spin>

</template>

<script>
import "./index.less";
import { message } from 'ant-design-vue';
import {
  addGroup,
  deleteGroup,
  getGroups,
} from "@/libs/apis/group";
import {setAuthor} from "@/libs/utils/author";
import clientMixin from "@/mixins/clientMixin";
import linkMixin from "@/mixins/linkMixin";
import {getGroupCares} from "@/libs/apis/groupcare";
import {setCareLang} from "@/libs/utils/langStore";
import {addInstanzes, instanzes_show, updateInstanzeUserPool} from "@/libs/apis/instanze";
import GroupSetting from "@/pages/admin/components/GroupSetting.vue";
import { Sketch } from 'vue-color'
import {getAvailableStorage} from "@/mixins/AvailableStorage";
import {acceptInvitation, getInvitations, getUsergroupcares} from "@/libs/apis/invitation";
import {getServiceAdmin, getServices, subscribeService} from "@/libs/apis/service";

const getUrlArgObject = () => {
  let args=new Object();
  let query=location.search.substring(1);//获取查询串
  var arr =[];
  var url ='';
  if(!query){
    url = decodeURIComponent(document.location.toString());
    arr = url.split("?");
    if(arr.length>1){
      for (let i = 1; i < arr.length; i++) {
        query+='?'+arr[i];
      }
      query=query.substring(1);

    }
  }
  let pairs=query.split("&");//在逗号处断开
  for(let i=0;i<pairs.length;i++){
    let pos=pairs[i].indexOf('=');//查找name=value
    if(pos==-1){//如果没有找到就跳过
      continue;
    }
    let argname=pairs[i].substring(0,pos);//提取name
    let value=pairs[i].substring(pos+1);//提取value
    args[argname]=unescape(value);//存为属性
  }
  return args;//返回对象
};
const {sessionStorage}= getAvailableStorage();
export default{
	data(){
		return {
      inited:false,
      careInvites:[],
      deleteLoading:false,
      delete_group_id:null,
      visible:false,
      editVisible:false,
      addMailInputVisible:false,
      addMessengerVisible:false,
      addMailInputValue:null,
      editRow:{},
      formLabelWidth:'120px',
      spinning:false,
      owner_id:43,
      tableHeight:document.documentElement.clientHeight - 100,
      tableData:[],
      deleteRow:{},
      auth_url:'',
      dialogAddMailVisible:false,
      handerRow:null,
      email:'',
      name:'',
      userid:null,
      instanzed:false,
      instanzeing:false,
      hadGetlist:false,
      showService:false,
      user_pool_id:null,
      usefor_user_pool_ids:[
          'c2c85a84-146d-4110-b636-c47a1dc04b88',
          '6db227c7-0ec4-4b2b-b605-05574557a140',
      ],
      services:[],
      is_manager: true,
		};
	},
	mounted: function(){
    const parms=getUrlArgObject();
    this.user_pool_id=parms.user_pool_id;
    if(parms.yme_origin){
      sessionStorage.setItem('yme_origin',parms.yme_origin);
    }
    this.email=parms.email;
    this.userid=parms.userId||parms.userid;
    this.name=parms.username||parms.name;
    sessionStorage.setItem('userid',this.userid)
    this.init()
	},
	components: {
    'sketch-picker': Sketch,
    GroupSetting,

  },
  computed:{

    invitation_able(){
      if(this.usefor_user_pool_ids.indexOf(this.user_pool_id)>-1){
        return true;
      }
      return false;
    }
  },

	methods: {
    onChoiceCare(care){
        const url= this.createLink(care.group_id,care.uid);
        console.log('重定向',url)
        if(window.top!==window.self){
          this.postMsg('care_redirect',"重定向B",{
            url
          })
        }else{
          location.href=url;
        }
    },
    /**
     *
     * @returns {Promise<boolean>} 是否需要跳转到工作台页面
     */
    async leadInvitation(){
      // 查询该用户邮箱下的有效邀请
     const rs= await getInvitations(this.user_pool_id,{
        filter:{
          identity:this.email,
          status:'1',
        }
      });
      // 接受这些有效邀请，相当于帮用户在该分组下，创建一个虚拟客服
     if(rs.data.length){
       for (let i = 0; i < rs.data.length; i++) {
            const invite= rs.data[i];
            await acceptInvitation(invite.id,this.email,this.userid,this.name);
       }
     }
    const  tags=[
        {
          name:this.email,
          type:'email',
        }
      ]
      // let map = new Map();
      // map.set('tags', tags);

      // new Map([
      //   ['word', this.word],
      // ]),

      // 自动进入工作台
     const cares=await getUsergroupcares(this.userid,{
       tags,
     });
     this.careInvites=cares;
     if(cares.length>0){
        this.inited=true;
        if(cares.length===1){
          this.onChoiceCare(cares[0]);
        }
        return true;
     }else{
       return false;
     }
     // this.$confirm('您的客服账号不存在或已被删除');
     // return true;
    },
    oncloseSetting(){
      this.editVisible=false;

      this.getList()
      let that=this;
      setTimeout(function () {
        that.editRow={};
      },500);
    },
    // toggleColorPicker(val=null){
    //   if(val){
    //     this.showColorPicker=val;
    //   }else {
    //     this.showColorPicker=!this.showColorPicker;
    //   }
    // },
    // getColor(row){
    //   if(row?.colors?.rgba){
    //     const rbga=row?.colors?.rgba;
    //     const color = `rgba(${rbga.r},${rbga.g},${rbga.b},${rbga.a})`;
    //     return color;
    //   }
    //
    //   return '';
    // },
    // async handleDeleteMessenger(messenger){
    //   let that = this;
    //   this.$confirm(
    //       this.$t('care.messenger.messenger_delete_tip')
    //       ,this.$t('care.messenger.messenger_delete_title')
    //       ,{
    //         type:'warning',
    //         cancelButtonText:this.$t('care.messenger.cancel'),
    //         confirmButtonText: this.$t('care.messenger.messenger_delete_force')
    //       }
    //   ).then(async ()=>{
    //     let editRow=that.editRow
    //     remove(editRow.messengers,item=>item===messenger);
    //     that.editRow={...editRow  };
    //     await deleteMessenger(messenger.group_id,messenger.id);
    //     that.$message.success('success')
    //   });
    // },
    // updateColor(){
    //   this.changeLimit(this.editRow)
    // },
    // async addMessenger(){
    //   const addMessengerRow=this.addMessengerRow;
    //   if(addMessengerRow.provider==='whatsapp'){
    //     if(!addMessengerRow.business_id
    //       ||!addMessengerRow.auth_id
    //       ||!addMessengerRow.token
    //       ||!addMessengerRow.phone_number_id
    //       ||!addMessengerRow.group_id
    //     ) {
    //         this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
    //        return;
    //     }
    //     try {
    //       const messenger = await  postAddMessenger(addMessengerRow.provider,
    //           addMessengerRow.business_id,
    //           addMessengerRow.phone_number_id,
    //           addMessengerRow.auth_id,
    //           addMessengerRow.token,
    //           addMessengerRow.group_id,
    //           addMessengerRow.name,
    //       );
    //       let editRow=this.editRow;
    //       remove(editRow.messengers,(item)=>item.phone_number_id==messenger.conf_identify);
    //       editRow.messengers.push(messenger);
    //       this.editRow=editRow;
    //       this.addMessengerVisible=false;
    //     }catch (e) {
    //       console.log(e)
    //       this.$message.warning(e.data.message);
    //
    //     }
    //
    //
    //   }
    //
    // },
    // async handleCloseEmail(delItem){
    //   let editRow=this.editRow;
    //   let emails=editRow.emails;
    //   remove(emails,item=>item==delItem)
    //   editRow.emails=emails
    //   this.editRow={
    //     ...editRow
    //   };
    //   await delmail(this.editRow.id,delItem);
    // },
    // showInput(){
    //   this.addMailInputVisible=true;
    // },
    // async addBiz(){
    //   if(!this.addBizRow.business_id
    //   ||!this.addBizRow.business_name){
    //     return;
    //   }
    //   await addBusiness( this.addBizRow.provider, this.addBizRow.business_id, this.addBizRow.business_name);
    //   this.bizs=await getBusinessList(this.addBizRow.provider);
    //   this.addBizVisible=false;
    //   this.addBizRow.business_id=null;
    //   this.addBizRow.business_name=null;
    //
    // },
    // async onAddMessenger(){
    //   this.addMessengerVisible=true;
    //   this.bizs=await getBusinessList(this.addBizRow.provider);
    // },
    // async onAddBiz(){
    //   this.addBizVisible=true;
    //
    // },
    // async handleInputConfirm(){
    //   if(this.addMailInputValue&&this.editRow.emails.indexOf(this.addMailInputValue)==-1){
    //     this.editRow.emails.push(this.addMailInputValue);
    //     this.addMailInputVisible=false;
    //     try {
    //       await addmail(this.editRow.id,this.addMailInputValue);
    //     }catch (res) {
    //       message.error(res.data.message)
    //       return;
    //     }
    //     this.addMailInputValue=null;
    //
    //   }
    // },
    async init(){
      console.log('init')
      const args=this.getUrlArgObject();
      if(!args.Authorization){
        message.error(this.$t('care.Authorization'));
        this.postMsg('init_err',this.$t('care.Authorization'))
        return
      }
      const locale=args?.locale??this.$i18n.locale;
      this.$i18n.locale=locale;
      setCareLang(locale);
      this.owner_id='0';
      setAuthor(args.Authorization);
      if((args.owner!==undefined && parseInt(args.owner)===1)){
        console.log('owner');
      }else if(this.invitation_able){
        const needJump=await this.leadInvitation();
        if(needJump){
          return;
        }
      }else{
        this.is_manager=false;
      }
      sessionStorage.setItem('owner_id',this.owner_id);
      this.$nextTick(function () {
        // 监听窗口大小变化
        let self = this;
        window.onresize = function() {
          self.tableHeight = document.documentElement.clientHeight - 100;
        }
      });
      const instanze=await instanzes_show();
      this.inited=true;
      if(instanze.instanze===null) {
        message.error(this.$t('care.instanze.nofound_tip'));
        return ;
      }else{
        this.instanzed=true;
      }
      this.getList();

      if(!instanze.instanze.user_pool_id&&(this.user_pool_id==='6db227c7-0ec4-4b2b-b605-05574557a140'||this.user_pool_id==='c2c85a84-146d-4110-b636-c47a1dc04b88')){
        updateInstanzeUserPool({
          user_pool_id:this.user_pool_id
        });
      }
    },
    onChangeEditRow(row){
      this.editRow=row;
    },
    // async changeLimit(group){
    //
    //   let colors=null;
    //   if(group.colors &&typeof group.colors !=='string') {
    //     colors=JSON.stringify(group.colors)
    //   }
    //     await  updateGroup(group.id,{
    //       ...group,
    //       colors,
    //     });
    // },
    // async onDelMail(row,email){
    //   await delmail(row.id,email);
    //   message.success(this.$t('care.opsSuccess'))
    //   this.getList();
    // },
    // async addMail(){
    //   if(!this.handerRow) {
    //     message.error(this.$t('care.choiceGroup'))
    //     this.dialogAddMailVisible=false;
    //     return;
    //   }
    //   try {
    //     await addmail(this.handerRow.id,this.email);
    //   }catch (res) {
    //     message.error(res.data.message)
    //     return;
    //   }
    //
    //   message.success(this.$t('care.opsSuccess'))
    //   this.dialogAddMailVisible=false;
    //   this.email=null;
    //   this.getList();
    // },
    // async handleAddMail(row){
    //   this.handerRow=row;
    //   this.dialogAddMailVisible=true;
    //   this.bizs=await getBusinessList(this.addBizRow.provider);
    // },
    async handleOk(){
      if(this.deleteRow.id!=this.delete_group_id){
        this.$message.warning("填写分组id来确认删除");
        return;
      }
      try {
        this.deleteLoading=true;
        await deleteGroup(this.deleteRow.id)
        this.deleteLoading=false;
        this.visible=false;
        message.success(this.$t('care.opsSuccess'));
        this.getList();
      }catch (e) {
        message.error(e);
      }
    },
    async getList(){
      let that=this;
      this.spinning=true;
      let owner_id=this.owner_id;
      const rs=await getGroups(owner_id,{
        append:['emails'],
        include:['messengers','webhook'],
      });
     const data=rs.data.map(item=>{
       item.emails=item.emails?item.emails.map(email=>email.email):[];
       item.colors=item.colors?JSON.parse(item.colors):{
         rgba:{ r:75,g:172,b:255,a:0.92 }
       }
       return item;
      })
      this.tableData= data;
      // 旧数据补充租户id
      // for (let i = 0; i < rs.data.length; i++) {
      //   const group=rs.data[i];
      //   if(group.tenant_id===null){
      //     updateGroupTenantId(group.id);
      //   }
      // }
      if(!this.tableData||this.tableData.length===0){
        this.$confirm(this.$t('care.addGroupConfirm'), this.$t('care.tips'), {
          confirmButtonText: this.$t('care.add'),
          cancelButtonText: this.$t('care.cancel'),
          type: 'warning'
        }).then(async () => {
          await addGroup(this.owner_id,'default');
          message.success(this.$t('care.opsSuccess'));
          that.getList()
        });
      }
      this.hadGetlist=true;
      this.spinning=false;
    },
    handleCustomLink(index,row){
      const url= this.createCustomLink(row.id);
      this.postMsg('custom_link',JSON.stringify({
        url,
      }))
      this.copy(url);
    },
    async handleLink(index,row){
      const rs=await getGroupCares(row.id);
      if(rs.data.length==0){
        message.warning(this.$t('care.setCare'))
        return;
      }
      this.postMsg('group',JSON.stringify({
        id:row.id,
      }))
      this.copy(row.id);
    },
    async handleShowService(){
      const services=await getServices();
      this.services= services.map(service=>{
        return {
          ...service,
          loading_go_admin:false,
          loading_subscribe:false,
        }
      });
      this.showService=true;

    },
    async onGoAdmin(service){
      service.loading_go_admin=true;
      const rs=await getServiceAdmin(service.service_id);
      window.open(`${rs.link}&locale=${this.$i18n.locale}`);
      this.handleShowService();
    },
    async onSubscribe(service){
      service.loading_subscribe=true;
      await subscribeService(service.service_id);
      this.$message.success('success');
      this.handleShowService();
    },
    handleAdd(){
      this.$router.push({ name: 'addgroup', params: { owner_id: this.owner_id }});
    },
    handleEdit(index,row){
      sessionStorage.setItem('group_id',row.id);
      this.$router.push({ name: 'carelist', params: { owner_id: this.owner_id,group_id: row.id }});
		},
    handleInvitation(index,row){
      sessionStorage.setItem('group_id',row.id);
      sessionStorage.setItem('user_pool_id',this.user_pool_id);
      this.$router.push({ name: 'invitationlist', params: { owner_id: this.owner_id,user_pool_id: this.user_pool_id }});
		},
    handleTpl(messenger){
      console.log(messenger)
      sessionStorage.setItem('group_id',messenger.group_id);
      sessionStorage.setItem('messenger_id',messenger.id);
      this.$router.push({ name: 'tpl', params: { messenger_id:messenger.id,group_id: messenger.group_id }});
    },
    handleDelete(index,row){
      this.visible=true;
      this.deleteRow=row;
    },
    handleSetting(index,row){
      this.editRow=row;
      this.editVisible=true;

    },
    async instanze(){
      this.instanzeing=true;
      await addInstanzes();
      this.instanzeing=false;
      this.instanzed=true;
      this.init()
    }
	},
  mixins:[clientMixin,linkMixin],
};
</script>
