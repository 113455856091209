import LocalStore from "@/libs/utils/LocalStore";
import uploadMixin from "@/mixins/uploadMixin";
import {message} from "ant-design-vue";

const clientMixin = {
    data(){
        return {
            online:false,
            offlineVisible:false,
        };
    },
    methods: {
       async checkAndRelogin(){
            const client=this.getClient();
            if(!this.getOnline()){
                await this.$confirm(this.$t('care.workbenchPage.offlineTips'),
                    this.$t('care.workbenchPage.sysWarning'), {
                    confirmButtonText: this.$t('care.workbenchPage.reLogin'),
                    showCancelButton: false,
                    showClose: false,
                    distinguishCancelAndClose: false,
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    iconClass: 'el-icon-warning',
                })
                const loading = this.$loading({
                    lock: true,
                    text: 'Login',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                try {

                    await client.logout();
                    await client.login(this.rtmToken);
                    this.setOnline(true)
                    message.success(this.$t('care.workbenchPage.reLoginSuccess'))
                    loading.close();
                }catch (e) {
                    message.error(this.$t('care.workbenchPage.reLoginFail'))
                    loading.close();
                    this.offlineVisible=true;
                }

            }
        },
        setOnline(online){
            LocalStore.set('client_online_'+this.rtmToken.uid,online);
        },
        getOnline(){
          return LocalStore.get('client_online_'+this.rtmToken.uid,false);
        },
        getClient() {
            return LocalStore.get('client_'+this.rtmToken.uid,null);
        },
        setClient(client){
            LocalStore.set('client_'+this.rtmToken.uid,client);
        },
        onPeerOffline: function () {
            this.checkAndRelogin();
            // this.$confirm('偵測到您已處於離線狀態，可能原因是您的網路中斷或在別處登入，請嘗試重新登陸接收最新消息', '系統警告', {
            //     confirmButtonText: '重新登陸',
            //     showCancelButton: false,
            //     showClose: false,
            //     distinguishCancelAndClose: false,
            //     closeOnPressEscape: false,
            //     closeOnClickModal: false,
            //     iconClass: 'el-icon-warning',
            // }).then(() => {
            //     window.location.reload();
            // })
        },
        async queryonLine(){
            const client= this.getClient();
            if(client&&this.uids.length>0){
                const res= await client.queryPeersOnlineStatus(this.uids);
               // console.log('在线状态',res);
                this.onlineState=res;
            }
        },
        // 客户端事件监听
        async imgPayLoad(blob) {
            const base64 = await this.blobToBase64(blob);
            // const payload = `<img style="width: 200px;height: 200px; object-fit: cover;" src='${base64}'/>`;
            return base64;
        },
        blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    if(e.target&&e.target.result){
                        resolve(e.target.result);
                    }else{ reject(new Error('blobToBase64 error'));}

                };
                // readAsDataURL
                fileReader.readAsDataURL(blob);
                fileReader.onerror = () => {
                    reject(new Error('blobToBase64 error'));
                };
            });
        },
        getQueryString(name){
            const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
            const r = window.location.search.substr(1).match(reg) || window.location.hash.substring((window.location.hash.search(/\?/)) + 1).match(reg);
            if (r != null) {
                return decodeURIComponent(r[2]);
            }else{
                return null;
            }
        },
        //返回的是对象形式的参数
        getUrlArgObject(){
            let args=new Object();
            let query=location.search.substring(1);//获取查询串
            if(!query){
                var url = decodeURIComponent(document.location.toString());
                var arr = url.split("?");
                if(arr.length>1){
                    query=arr[1];
                }
            }
            let pairs=query.split("&");//在逗号处断开
            for(let i=0;i<pairs.length;i++){
                let pos=pairs[i].indexOf('=');//查找name=value
                if(pos==-1){//如果没有找到就跳过
                    continue;
                }
                let argname=pairs[i].substring(0,pos);//提取name
                let value=pairs[i].substring(pos+1);//提取value
                args[argname]=unescape(value);//存为属性
            }
            return args;//返回对象
        },

        postMsg(type,message,payload=''){
            window.parent.postMessage({type,message,payload}, '*');
        },
        message2postmsg(type,msg){
            message.warning(msg).then(()=>{
                this.postMsg(type,msg)
            })
        },
    },
    mixins:[uploadMixin],
}
export default clientMixin;
