import {QueryModel} from "@/libs/models";
import request from "@/libs/utils/request";
import parse from "@/libs/utils/QueryParser";
import {front_api} from "@/libs/constants/const";

export const saveMessage = (sender_uid:string,
                             receiver_uid:string,
                             payload:string,
                             state:0|1,
                             type:'text'|'html'|'link'|'img'|'markdown'|'video'|'action'|'system'|'agora_img',
                            group_id:string,
                            connect_id:string,
                            messenger_id:string|null=null,
                            messengertpl_id:string|null=null,
                            ext:any|null=null,
                            messengertpl_params:any|null=null
):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}messages`,
        method: 'post',
        data:{
            sender_uid,
            receiver_uid,
            payload,
            state,
            type,
            group_id,
            connect_id,
            messenger_id,
            messengertpl_id,
            ext,
            messengertpl_params,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const readMessages = (connect_id:string,
                             you:string
):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}messages/read`,
        method: 'post',
        data:{
            connect_id,
            you,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const one2oneMessages = ( group_id:string,
                                 connect_id:string,
                                query?:QueryModel
):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}messages/one2one${parse(query,`group_id=${group_id}&connect_id=${connect_id}`)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getMessageDetails = ( id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}messages/${id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const careFeed = ( group_id:string,
                                 uid:string,
                                query?:QueryModel
):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}messages/care_feed${parse(query,`group_id=${group_id}&uid=${uid}`)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
