import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";

// 查询实例是否创建
export const instanzes_show=():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}instanzes_show`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

// 创建实例
export const addInstanzes=():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}instanzes`,
        method: 'post',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const updateInstanzeUserPool=({user_pool_id}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}instanze_user_pool`,
        method: 'post',
        data:{user_pool_id}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});