<template>
    <div v-if="visible" :class="[inner?'':'fixed_card']">
      <div v-if="!inner">
        <el-card :shadow="'never'" style="width: 300px; " :body-style="{padding:'0'}">
          <div style="background-color: #eeeeee; padding: 10px; font-size: large;display:flex;justify-content: space-between;align-items: center;">
            <span>{{$t('care.tpl_preview')}}</span>
            <i style="cursor: pointer;" @click="handleClose" class="el-icon-close"></i>
          </div>
          <div :style="{padding:'10px 15px',backgroundColor:'rgb(229,221,213)'}">
            <el-card :shadow="'never'" :body-style="{padding:'10px'}">
              <div class="head" v-if="header">
                <template v-if="header.format==='IMAGE'">
                  <img  style="width: 100%;object-fit: contain; "
                        :src="header.example.header_handle[0]"
                        class="image">
                </template>
                <template v-else-if="header.format==='VIDEO'">
                  <video controls style="width: 100%;height:140px; object-fit: cover; " class="image" :src="header.example.header_handle[0]"></video>
                </template>
                <template v-else-if="header.format==='DOCUMENT'">
                  <div style="height: 150px;display: flex;align-items: center;justify-content: center;background-color: rgb(221,221,221);">
                    PDF DOCUMENT
                  </div>
                </template>
                <template v-else-if="header.format==='LOCATION'">
                  <div style="height: 150px;display: flex;align-items: center;justify-content: center;background-color: rgb(221,221,221);">
                    LOCATION
                  </div>
                </template>
                <template v-else-if="header.format==='TEXT'">
                  <span>{{header.text}}</span>
                </template>

              </div>
              <div class="head" v-if="body">
                {{body.text}}
              </div>
              <div  class="head" style="color: #999999;" v-if="footer">
                {{footer.text}}
              </div>
              <div class="head" style="padding: 4px;text-align: center;color: #1890ff;"  v-if="buttons">
                <div class="bottom clearfix">
                  <div v-for="btn in buttons.buttons" :key="btn.text" style="border-top: 1px solid #EEE;">
                    <i class="icon el-icon-phone-outline" v-if="btn.type==='PHONE_NUMBER'"></i>
                    <i class="icon el-icon-s-promotion" v-else-if="btn.type==='URL'"></i>
                    <i class="icon el-icon-share" v-else-if="btn.type==='QUICK_REPLY'"></i>
                    <el-button type="text" class="button">
                      {{btn.text}}
                    </el-button>

                  </div>

                </div>
              </div>
            </el-card>
          </div>

        </el-card>
      </div>
      <div v-else>
        <div :style="{padding:'0',backgroundColor:'#ffffff'}">
          <el-card :shadow="'never'" :body-style="{padding:'10px'}">
            <div class="head" v-if="header">
              <template v-if="header.format==='IMAGE'">
                <img  style="width: 100%;object-fit: contain; "
                      :src="header.example.header_handle[0]"
                      class="image">
              </template>
              <template v-else-if="header.format==='VIDEO'">
                <video controls style="width: 100%;height:140px; object-fit: cover; " class="image" :src="header.example.header_handle[0]"></video>
              </template>
              <template v-else-if="header.format==='DOCUMENT'">
                <div style="height: 150px;display: flex;align-items: center;justify-content: center;background-color: rgb(221,221,221);">
                  PDF DOCUMENT
                </div>
              </template>
              <template v-else-if="header.format==='LOCATION'">
                <div style="height: 150px;display: flex;align-items: center;justify-content: center;background-color: rgb(221,221,221);">
                  LOCATION
                </div>
              </template>
              <template v-else-if="header.format==='TEXT'">
                <span v-html="getWithParamValTxt(header.text)"></span>
              </template>

            </div>
            <div class="head" v-if="body" v-html="getWithParamValTxt(body.text)"></div>
            <div  class="head" style="color: #999999;" v-if="footer">
              {{footer.text}}
            </div>
            <div class="head" style="padding: 4px;text-align: center;color: #1890ff;"  v-if="buttons">
              <div class="bottom clearfix">
                <div v-for="btn in buttons.buttons" :key="btn.text" style="border-top: 1px solid #EEE;">
                  <i class="icon el-icon-phone-outline" v-if="btn.type==='PHONE_NUMBER'"></i>
                  <i class="icon el-icon-s-promotion" v-else-if="btn.type==='URL'"></i>
                  <i class="icon el-icon-share" v-else-if="btn.type==='QUICK_REPLY'"></i>
                  <el-button type="text" class="button">
                    {{btn.text}}
                  </el-button>

                </div>

              </div>
            </div>
          </el-card>
        </div>
      </div>

    </div>
<!--  </el-dialog>-->

</template>

<script>
export default {
  name: "TplCard",
  props: [
    "tpl",
    "visible",
    "inner",
    "params",
  ],
  emits: ["onClose","update:visible"],
  data(){

     let header=null;
     let body=null;
     let footer=null;
     let buttons=null;
     JSON.parse(this.tpl)?.components?.map(item=>{
        switch (item.type.toLowerCase()) {
           case 'body':
             body=item;
             break;
           case 'header':
             header=item;
             break;
           case 'footer':
             footer=item;
             break;
           case 'buttons':
             buttons=item;
             break;
        }
    });
    return {
         header:header,
         body:body,
         footer:footer,
         buttons:buttons,
    }
  },
  methods:{
    getWithParamValTxt(txt){

      if(txt&&this.params&&this.params!='null'){
        const params= JSON.parse(this.params);
        for (const [key, value] of Object.entries(params)) {
          txt=txt.replace(`{{${key}}}`,`<i style="color:#1dad1d;font-weight: bolder;">${value}</i>`);
        }
      }
      return txt;
    },
    handleClose(){
      this.$emit('onClose');
    },
  },
}
</script>

<style scoped lang="less">
.fixed_card{
  position: fixed;top:10px;right: calc(50% - 150px);z-index: 1051;
}
.head{
  margin-bottom: 10px;
  text-align: left;
  .icon{
    margin-right: 4px;
  }
}
</style>