import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";
import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";

export const getMarketingBatchs= (group_id:string,care_uid:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
   const query2= parse(query,`care_uid=${care_uid}`)
    request({
        url: `${admin_api}groups/${group_id}/marketing_batchs${query2}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postMarketingBatch= (group_id:string,{
    name,
    care_uid,
    tpl_id,
    messenger_id,
    tpl_params,
    recips,
    time_type,
    plan_at,
}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/marketing_batchs`,
        method: 'post',
        data:{
            name,
            care_uid,
            tpl_id,
            messenger_id,
            tpl_params,
            recips,
            time_type,
            plan_at,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const cancelMarketingBatch= (group_id:string,task_id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/marketing_batch_cancel/${task_id}`,
        method: 'post',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getMarketingBatchDetails= (group_id:string,task_id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/marketing_batch/${task_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});