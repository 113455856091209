import LocalStore from "@/libs/utils/LocalStore";
import CryptoJS from "crypto-js";
import liveLocalStore from "@/libs/utils/LocalStore";
import {message} from "ant-design-vue";
import clientMixin from "@/mixins/clientMixin";
import {getAuthor} from "@/libs/utils/author";

const linkMixin = {
    data(){
        return {
          copying:false,
        };
    },
    methods:{
        createCustomLink(group_id){
            const host =window.location.protocol+'//'+ window.location.host;
            let url=`${host}/index.html#/custom?group_id=${group_id}`;
            return url;
        },
        createLink(group_id,care_uid,uid=null,provider=null)
        {
            const host = window.location.protocol+'//'+window.location.host;
            const Authorization=getAuthor();
            const args={
                Authorization,
                group_id:group_id,
                care_uid:care_uid,
                locale:this.$i18n.locale,
                timestamp:Math.round(new Date() / 1000),
                // origin:window.top.location.origin,
            };
            if(uid) args.uid=uid;
            if(provider) args.provider=provider;

            if(sessionStorage.getItem('yme_origin')){
                args.yme_origin=sessionStorage.getItem('yme_origin');
            }
            const sign= this.makeSignature(args,'dfoptkf9rj4jh58dfy67ft98t60fguj')
            args.sign=sign;
            let url=`${host}/index.html?`;
            let index=0;
            for(let key  in args){
                url+=((index===0? '':'&')+key+'='+args[key]);
                index++;
            }
            return url;
        },
        copy(text){
            this.copying=true;
            this.$nextTick(()=>{
                let input= document.createElement('input');
                input.setAttribute('display','none')
                input.value=text;
                document.body.appendChild(input)
                input.select();
                let res=document.execCommand("copy");
                this.copying=false;
                console.log('copy',res)
                if(res===true){
                    this.$message.success(this.$t('care.opsSuccess'));
                }else{
                    this.$message.error('error');

                }
                document.body.removeChild(input)
            })
        },
    },
    mixins:[clientMixin],
}
export default linkMixin;
