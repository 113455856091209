import request from "@/libs/utils/request";
import {code_need_pay,code_need_renewal} from "../../config/customCode";
import {admin_api} from "@/libs/constants/const";

export const addBot=(group_id:string,uid:string,name:string,description:string,strategy:string,strategy_details:any):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}carebots/${group_id}/${uid}/bots`,
        method: 'post',
        data:{
            description,
            name,
            strategy,
            strategy_details,
            provider:'topkee_cloud_bot',
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const postCreateWhatsappTplAI=(group_id:string,{messenger_provider,uid,theme,lang,prompt}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}carebots/${group_id}/create_whatsapp_tpl_by_bot`,
        method: 'post',
        data:{messenger_provider,uid,theme,lang,prompt}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const updateBot=(group_id:string,uid:string,id:string,name:string,description:string,strategy:string,strategy_details:any):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}carebots/${group_id}/${uid}/bots/${id}`,
        method: 'put',
        data:{
            description,
            name,
            strategy,
            strategy_details,
            provider:'topkee_cloud_bot',
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getBots=(group_id:string,uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}carebots/${group_id}/${uid}/bots`,
        method: 'get',
    }).then((response: any) => {
        if(response.code===code_need_pay||response.code===code_need_renewal){
            reject(response)
        }
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const botServiceAvailable=():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}carebots/service_available`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data.subscription);
    }).catch((e) => reject(e));
});