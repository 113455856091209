<template>
  <el-drawer
      :wrapperClosable="false"
      :title="$t('care.marketing_batchs.list_title')"
      :visible.sync="visible"
      @close="onClose"
      append-to-body
      size="95%"
  >
    <div style="height:100%;padding: 30px">
      <div class="list" style="height:100%;">
        <div class="header">
          <el-input @keyup.enter.native="onSearch" @clear="onSearch" clearable size="mini" style="max-width: 300px;margin-right: 10px;" placeholder="search" v-model="filter.name">
            <el-button  @click="onSearch"  slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <el-radio-group @change="onSearch" style="margin-right: 10px;" size="mini" v-model="filter.status">
            <el-radio-button label="">All</el-radio-button>
            <el-radio-button label="0">{{$t('care.marketing_batchs.status_0')}}</el-radio-button>
            <el-radio-button label="1">{{$t('care.marketing_batchs.status_1')}}</el-radio-button>
            <el-radio-button label="2">{{$t('care.marketing_batchs.status_2')}}</el-radio-button>
            <el-radio-button label="-1">{{$t('care.marketing_batchs.status_-1')}}</el-radio-button>
            <el-radio-button label="-2">{{$t('care.marketing_batchs.status_-2')}}</el-radio-button>
          </el-radio-group>
          <el-radio-group @change="onSearch" style="margin-right: 10px;" size="mini" v-model="filter.time_type">
            <el-radio-button label="">All</el-radio-button>
            <el-radio-button label="0">{{$t('care.marketing_batchs.time_type_0')}}</el-radio-button>
            <el-radio-button label="1">{{$t('care.marketing_batchs.time_type_1')}}</el-radio-button>
           </el-radio-group>
          <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="reload" ></el-button>
          <el-button size="mini"  plain type="primary" icon="el-icon-plus" @click="formVisible=true" >{{$t("care.add")}}</el-button>
        </div>
        <div class="content" style="height:calc( 100% - 40px);">
          <el-table
              class="table"
              v-loading="spinning"
              :data="data"
              height="calc( 100vh - 190px)"
              highlight-current-row
              row-key="id"
          >

            <el-table-column
                prop="name"
                :label="$t('care.marketing_batchs.batch_name')"
            ></el-table-column>

            <el-table-column
                prop="messenger_id"
                :label="$t('care.marketing_batchs.messenger_id')"
            ></el-table-column>

            <el-table-column
                prop="status"
                :label="$t('care.marketing_batchs.status')"
            >
              <template slot-scope="scope">
                <el-tag
                    style="border:0;"
                    size="mini"
                    :hit="false"
                    :color="getBatchStatusColor(scope.row.status)"
                    effect="dark">
                  {{getBatchStatusStr(scope.row.status)}}
                </el-tag>

              </template>

            </el-table-column>

            <el-table-column
                prop="recips"
                :label="$t('care.marketing_batchs.recips')"
            >
              <template slot-scope="scope">
               <div class="total">
                 <div>
                   <span>All：</span> <span>{{scope.row.recips.length}}</span>
                 </div>
                 <div>
                   <span>{{$t('care.marketing_batchs.status_1')}}：</span> <span style="color: #00ba6e;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,[1])}}</span>
                 </div>
                 <div>
                   <span>{{$t('care.marketing_batchs.status_2')}}：</span> <span style="color: #00ba6e;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,[2])}}</span>
                 </div>
                 <div>
                   <span>{{$t('care.marketing_batchs.status_success')}}：</span> <span style="color: #00ba6e;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,[3,4,5])}}</span>
                 </div>
<!--                 <div>-->
<!--                   <span>{{$t('care.marketing_batchs.recip_statu_3')}}：</span> <span style="color: #00ba6e;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,3)}}</span>-->
<!--                 </div>-->
<!--                 <div>-->
<!--                   <span>{{$t('care.marketing_batchs.recip_statu_5')}}：</span> <span style="color: #00ba6e;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,5)}}</span>-->
<!--                 </div>-->
                 <div>
                   <span>{{$t('care.marketing_batchs.recip_statu_fail')}}：</span> <span style="color: #f66161;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,[-1,-2,-4])}}</span>
                 </div>
<!--                 <div>-->
<!--                   <span>{{$t('care.marketing_batchs.status_-1')}}：</span> <span style="color: #f66161;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,-1)}}</span>-->
<!--                 </div>-->
<!--                 <div v-if="getRecipsByStatus(scope.row.recips,-2)">-->
<!--                   <span>{{$t('care.marketing_batchs.status_-2')}}：</span> <span style="color: #555;font-weight: bolder;">{{getRecipsByStatus(scope.row.recips,-2)}}</span>-->
<!--                 </div>-->
               </div>

              </template>
            </el-table-column>
            <el-table-column
                prop="created_at"
                :label="$t('care.marketing_batchs.created_at')"
            ></el-table-column>
            <el-table-column
                prop="time_type"
                :label="$t('care.marketing_batchs.time_type')"
            >
              <template slot-scope="scope">
                <el-tag
                    size="mini"
                    :type="scope.row.time_type?'success':''"
                    effect="dark">
                  {{$t(`care.marketing_batchs.time_type_${scope.row.time_type}`)}}
                </el-tag>
                <div>
                  <span style="font-size: smaller;" v-if="scope.row.time_type===1"> ({{scope.row.plan_at}})</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                width="350"
                prop="tpl_id"
                :label="$t('care.message_page.preview_tpl')"
            >
              <template slot-scope="scope">
               <div class="hidden_scrollbar" v-if="scope.row.tpl&&scope.row.tpl.data" style="width: 300px;max-height: 500px;overflow-y: auto;">
                 <TplCard :params="scope.row.tpl_params" :inner="true" :visible="true" :tpl="scope.row.tpl.data"/>
               </div>
                <div v-else>Empty</div>
              </template>
            </el-table-column>
            <el-table-column
                width="130"
                :label="$t('care.ops')"
            >
              <template slot-scope="scope">
                <div class="options">
<!--                  <div>-->
<!--                    <el-button-->
<!--                        :title="$t('care.delete')"-->
<!--                        size="mini"-->
<!--                        type="danger" plain-->
<!--                        icon="el-icon-delete"-->
<!--                        @click="handleDelete(scope.$index, scope.row)"></el-button>-->
<!--                  </div>-->

                  <el-button style="margin-bottom: 5px;" @click="showTaskMarketingBatchDetail(scope.row)" type="primary" size="mini">
                    {{$t('care.marketing_batchs.detail_task')}}
                  </el-button>
                  <div v-if="scope.row.status===0">
                    <el-popconfirm
                        :title="$t('care.marketing_batchs.cancel_task_sure')"
                        :confirm-button-text="$t('care.messenger.sure')"
                        :cancel-button-text="$t('care.messenger.cancel')"
                        @confirm="handleCancel(scope.$index, scope.row)"
                    >
                      <el-button
                          slot="reference"
                          :title="$t('care.cancel')"
                          size="mini"
                          type="danger">{{$t('care.marketing_batchs.cancel_task')}}</el-button>
                    </el-popconfirm>

                  </div>
                </div>
              </template>
            </el-table-column>

          </el-table>
          <el-pagination
              small
              layout="prev, pager, next"
              :current-page.sync="page"
              :page-size="pageSize"
              @current-change="pageChange"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onClose()">{{$t('care.messenger.close')}}</el-button>
    </div>
    <MarketingBatchForm @onSuccess="reload" v-if="formVisible" :visible="formVisible"  @onClose="formVisible=false" :group_id="group_id" :care_uid="care_uid"></MarketingBatchForm>
    <MarketingBatchDetail v-if="showDetail" :visible="showDetail"  @onClose="showDetail=false" :group_id="group_id" :row="detailRow"></MarketingBatchDetail>
  </el-drawer>
</template>

<script>
import {cancelMarketingBatch, getMarketingBatchs} from "@/libs/apis/marketingbatch";
import MarketingBatchForm from "@/pages/admin/components/MarketingBatchForm.vue";
import MarketingBatchDetail from "@/pages/admin/components/MarketingBatchDetail.vue";
import TplCard from "@/pages/admin/components/TplCard.vue";

export default {
  name: "MarketingBatchDrawer",
  props: [
    "group_id",
    "care_uid",
    "visible",
  ],
  components:{
    MarketingBatchForm,
    TplCard,
    MarketingBatchDetail,
  },
  data(){
    return {
      formLabelWidth:'120px',
      formItemLoading:false,
      formVisible:false,
      form:{},
      spinning:false,
      data:[],
      pageSize:10,
      page:1,
      total:0,
      filter:{
        status:'',
        time_type:'',
        name:'',
      },
      showDetail:false,
      detailRow:null,
    };
  },
  mounted() {
    this.getList();
  },
  methods:{
    showTaskMarketingBatchDetail(row){
      this.detailRow=row;
      this.$nextTick(function () {
        this.showDetail=true;
      })
    },
    async handleCancel(index,row){
      await cancelMarketingBatch(this.group_id,row.id);
      this.reload();
    },
    getBatchStatusStr(status){
      return this.$t(`care.marketing_batchs.status_${status}`)
    },
    getBatchStatusColor(status){
      const colors={
        '0':'rgb(0,134,250)',
        '1':'#4ebb4e',
        '2':'#038f03',
        '-1':'#f66161',
        '-2':'gray',
      };
      return colors[status];
    },
    getRecipsByStatus(recips,status){
      if(typeof status == 'number'){
        return recips.filter(item=>item.status===status).length;
      }else{
        return recips.filter(item=>status.indexOf(item.status)>-1).length;
      }

    },
    onSearch(){
      this.reload();
    },
    reload(){
      this.page=1;
      this.total=0;
      this.getList();
    },
    onClose(){
      this.$emit("onClose");
    },
    async getList(){
      this.spinning=true;
      const {data,total}=await getMarketingBatchs(this.group_id,this.care_uid,{
        page:{
          number:this.page,
          size:this.pageSize,
        },
        filter:this.filter,
        include:['tpl','recips','messenger']
      });
      this.data=data;
      this.total=total;
      this.spinning=false;
    },
    pageChange(page){
      this.page=page;
      this.getList();
    },
  },
}
</script>

<style scoped lang="less">
.total{
  cursor: pointer;
  .el-icon-view{
    display: none;
  }
  &:hover{
    .el-icon-view{
      display: inline-block;
    }
  }
}
.options{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
/* 适用于 Firefox */
.hidden_scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* 适用于 Chrome, Safari, Edge 等基于 WebKit 的浏览器 */
.hidden_scrollbar::-webkit-scrollbar {
  display: none;
}
</style>