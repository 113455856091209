<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="$t('care.careform.add')"
      :visible="visible"
      @close="onClose"
      append-to-body>
    <el-form :model="careForm"  :label-width="formLabelWidth" style="max-width: 800px">
      <el-form-item :label="$t('care.careform.name')" prop="name">
        <div class="formval">
          <el-input  v-model="careForm.name"></el-input>
        </div>
      </el-form-item>
      <el-form-item :label="$t('care.careform.avatar')" prop="avatar">
        <div class="formval">
          <el-upload
              :class="['avatar-uploader',careForm.avatar?'avatar-uploader-ok':'']"
              action="string"
              :show-file-list="false"
              :before-upload="onBeforeUploadImage"
              :http-request="upload">
            <img v-if="careForm.avatar" :src="careForm.avatar" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item v-if="show_receive_able" :label="$t('care.receive_able')" prop="receive_able">
        <div class="formval">
          <el-switch
              :active-value=1
              :inactive-value=0
              v-model="careForm.receive_able">
          </el-switch>
        </div>
      </el-form-item>
      <el-form-item  v-if="careForm.uid" :label="$t('care.careform.bot_label')" prop="receive_able">
        <div class="formval" style="color: dodgerblue;">
          <div  @click="onEditBot('reply')" style="cursor: pointer;display: flex;align-items: center;">
            <a-icon style="font-size: 1.5em;margin-right: 5px;" :component="botSvg"  />
            <span>{{$t('care.botform.role_label_reply')}}</span>
          </div>
        </div>
      </el-form-item>

      <el-form-item  v-if="careForm.uid" :label="$t('care.botform.welcome_word')" prop="welcomeWord">
        <div class="formval" style="color: dodgerblue;">
          <div  @click="onWelcomeWordList" style="cursor: pointer;display: flex;align-items: center;">
            <span>{{$t('care.botform.welcome_word')}}</span>
            <i class="el-icon-right"></i>
          </div>
        </div>
      </el-form-item>

      <el-form-item  v-if="careForm.uid" :label="$t('care.marketing_batchs.list_title')" prop="welcomeWord">
        <div class="formval" style="color: dodgerblue;">
          <div  @click="marketingBatchsVisible=true" style="cursor: pointer;display: flex;align-items: center;">
            <span>{{$t('care.marketing_batchs.list_title')}}</span>
            <i class="el-icon-right"></i>
          </div>
        </div>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">{{$t('care.messenger.cancel')}}</el-button>
      <el-button :loading="addCareLoading" type="primary" @click="saveCareForm">{{$t('care.messenger.sure')}}</el-button>
    </div>
    <OrderRenewal v-if="order_status_result_visible" @onClose="order_status_result_visible=false" :group_id="group_id" :visible="order_status_result_visible" :title="order_status_title"></OrderRenewal>
    <BotForm v-if="botFormVisible" :visible="botFormVisible"  @onClose="onCloseBotForm" :group_id="group_id" :uid="careForm.uid" ></BotForm>
    <MarketingBatchDrawer v-if="marketingBatchsVisible"  :visible="marketingBatchsVisible"  :group_id="group_id" :care_uid="careForm.uid"  @onClose="marketingBatchsVisible=false" ></MarketingBatchDrawer>
    <el-drawer
        size="90%"
        :title="$t('care.botform.welcome_word')"
        :visible.sync="visibleWelcomeList"
        @close="visibleWelcomeList=false"
        append-to-body>

      <div style="height:100%;padding: 30px">
        <div class="list" style="height:100%;">
          <div class="header">
            <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="loadWelcomeWordList()"></el-button>
            <el-button size="mini"  plain type="primary" icon="el-icon-plus"  @click="onAddWelcomeWord()">{{$t("care.add")}}</el-button>
          </div>
          <div class="content" style="height:calc( 100% - 40px);">
            <el-table
                class="table"
                v-loading="getWelcomeWordsLoading"
                :data="welcomeWords"
                height="calc( 100vh - 190px)"
                highlight-current-row
            >
              <el-table-column
                  prop="cycle_type"
                  :label="$t('care.welcome_word.cycle_type')"
              >
                <template slot-scope="scope">
                 <span style="font-weight: bolder;">
                    {{ $t('care.welcome_word.cycle_type_'+scope.row.cycle_type) }}
                 </span>
                  <i style="font-size: 10px;" v-if="scope.row.cycle_type=='weekly'" >( {{ $t('care.welcome_word.day_of_week'+scope.row.day_of_week) }})</i>
                </template>


              </el-table-column>

              <el-table-column
                  prop="start_at"
                  :label="$t('care.welcome_word.period')"
              >
                <template slot-scope="scope">
                   {{scope.row.start_at}} ~ {{scope.row.end_at}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="enable"
                  :label="$t('care.welcome_word.enabled')"
              >
                <template slot-scope="scope">
                  <el-switch
                      @change="onSwitchWelcomeWord(scope.row)"
                      :active-value="1"
                      :inactive-value="0"
                      v-model="scope.row.enabled"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                  prop="word"
                  :label="$t('care.welcomeForm.word')"
              ></el-table-column>

              <el-table-column
                  :label="$t('care.ops')"
              >
                <template slot-scope="scope">
                  <div class="options">
                    <el-popconfirm
                        :title="$t('care.delete_sure')"
                        :confirm-button-text="$t('care.messenger.sure')"
                        :cancel-button-text="$t('care.messenger.cancel')"
                        @confirm="onDeleteWelcomeWord(scope.row)"
                    >
                      <el-button
                          slot="reference"
                          size="mini"
                          type="danger" plain
                          icon="el-icon-delete"></el-button>
                    </el-popconfirm>

                    <el-button
                        style="margin-left: 10px;"
                        :title="$t('care.edit')"
                        size="mini"
                        type="primary" plain
                        icon="el-icon-edit"
                        @click="onEditWelcomeWord(scope.row)"></el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>

          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
        :title="$t('care.botform.welcome_word')"
        :visible="visibleWelcome"
        @close="visibleWelcome=false"
        append-to-body>
      <el-form :model="welcomeForm"  :label-width="formLabelWidth" style="max-width: 800px">
        <el-form-item >
          <div class="formval">
            <el-alert
                title="Tips"
                :description="$t('care.welcomeForm.tips')"
                type="success"
                show-icon
                >
            </el-alert>
          </div>
        </el-form-item>
        <el-form-item :label="$t('care.welcome_word.cycle_type')" prop="cycle_type">
          <div class="formval">
            <el-radio-group v-model="welcomeForm.cycle_type">
              <el-radio-button  label="daily">{{ $t('care.welcome_word.cycle_type_daily') }}</el-radio-button>
              <el-radio-button  label="weekly">{{ $t('care.welcome_word.cycle_type_weekly') }}</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item v-if="welcomeForm.cycle_type==='weekly'" :label="$t('care.welcome_word.day_of_week')" prop="day_of_week">
          <div class="formval">
            <el-radio-group v-model="welcomeForm.day_of_week">
              <el-radio-button  :label="0">{{ $t('care.welcome_word.day_of_week0') }}</el-radio-button>
              <el-radio-button  :label="1">{{ $t('care.welcome_word.day_of_week1') }}</el-radio-button>
              <el-radio-button  :label="2">{{ $t('care.welcome_word.day_of_week2') }}</el-radio-button>
              <el-radio-button  :label="3">{{ $t('care.welcome_word.day_of_week3') }}</el-radio-button>
              <el-radio-button  :label="4">{{ $t('care.welcome_word.day_of_week4') }}</el-radio-button>
              <el-radio-button  :label="5">{{ $t('care.welcome_word.day_of_week5') }}</el-radio-button>
              <el-radio-button  :label="6">{{ $t('care.welcome_word.day_of_week6') }}</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item :label="$t('care.welcome_word.period')">
          <div class="formval">
            <el-time-picker
                is-range
                v-model="welcomeForm.period"
                range-separator="~"
                start-placeholder="begin"
                end-placeholder="end">
            </el-time-picker>
          </div>
        </el-form-item>
        <el-form-item :label="$t('care.welcomeForm.word')" prop="word">
          <div class="formval">
            <el-input
               type="textarea"
               :rows="6"
               :maxlength="800"
               show-word-limit
               v-model="welcomeForm.word">
            </el-input>
          </div>
        </el-form-item>
<!--        <el-form-item :label="$t('care.welcomeForm.enabled')" prop="enabled">-->
<!--          <div class="formval">-->
<!--            <el-switch-->
<!--                :active-value=1-->
<!--                :inactive-value=0-->
<!--                v-model="welcomeForm.enabled">-->
<!--            </el-switch>-->
<!--          </div>-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visibleWelcome=false">{{$t('care.messenger.cancel')}}</el-button>
        <el-button :loading="saveWelcomeWordLoading" type="primary" @click="saveWelcomeForm">{{$t('care.messenger.sure')}}</el-button>
      </div>
    </el-dialog>
  </el-dialog>


</template>

<script>

import orderStateResultMixin from "../../../mixins/orderStateResultMixin";
import uploadMixin from "../../../mixins/uploadMixin";
import axios from "axios";
import BotForm from "@/pages/admin/components/BotForm.vue";
import BotSvg from "@/components/svgs/BotSvg";
import {postCare, postWelcomeWord, putCare} from "../../../libs/apis/care";
import OrderRenewal from "@/pages/admin/components/OrderRenewal.vue";
import {code_need_pay,code_need_renewal} from "../../../config/customCode";
import {deleteWelcomeWordV2, getWelcomeWords, postWelcomeWordV2, putWelcomeWordV2} from "@/libs/apis/welcomeword";
import MarketingBatchDrawer from "@/pages/admin/components/MarketingBatchDrawer.vue";
import dayjs from "dayjs";

export default {
  name: "CareForm",
  props: [
    "group_id",
    "visible",
    "show_receive_able",
    "row",
  ],
  components:{
    BotForm,
    OrderRenewal,
    MarketingBatchDrawer,
  },
  data(){
    return {
      botSvg:BotSvg,
      formLabelWidth:'180px',
      formItemLoading:false,
      careForm:{
        id:this.row?.id||null,
        uid:this.row?.uid||null,
        name:this.row?.name||null,
        avatar:this.row?.avatar||null,
        receive_able:this.row?.receive_able||0,
        welcome_word:this.row?.welcome_word||null,
      },
      welcomeForm:{
        word:null,
        enabled:1,
        day_of_week:1,
        cycle_type:'daily',
        period:[],
      },
      spinning:false,
      botFormVisible:false,
      addCareLoading:false,
      visibleWelcome:false,
      visibleWelcomeList:false,
      getWelcomeWordsLoading:true,
      welcomeWords:[],
      editWelcomeWord:null,
      saveWelcomeWordLoading:false,
      marketingBatchsVisible:false,

    };
  },
  watch:{
  },
  mounted() {

  },
  methods:{
    onAddWelcomeWord(){
      this.editWelcomeWord=null;
      this.welcomeForm={
            word:null,
            enabled:1,
            day_of_week:1,
            cycle_type:'daily',
            period:['2020-01-01 00:00:00','2020-01-01 23:59:59'],
      };
      this.visibleWelcome=true;
    },
    onSwitchWelcomeWord(welcome_word){
      this.onEditWelcomeWord(welcome_word,true);
      this.saveWelcomeForm();
    },
    async onDeleteWelcomeWord(welcome_word){
      await deleteWelcomeWordV2(this.group_id,this.careForm.id,welcome_word.id);
      this.$message.success("success");
      this.onWelcomeWordList();
    },
    onEditWelcomeWord(welcome_word,isSwitchEnable=false){
      this.editWelcomeWord=welcome_word;
      this.welcomeForm={
        ...welcome_word,
        period:['2020-01-01 '+welcome_word.start_at,'2020-01-01 '+ welcome_word.end_at],
      };
      if(!isSwitchEnable){
        this.visibleWelcome=true;
      }
    },
    onWelcomeWordList(){
      this.visibleWelcomeList=true;
      this.loadWelcomeWordList();
    },
    async loadWelcomeWordList(){
      this.getWelcomeWordsLoading=true;
      const {data,total}= await getWelcomeWords(this.group_id,this.careForm.id);
      this.welcomeWords=data;
      this.getWelcomeWordsLoading=false;
    },
    async saveWelcomeForm(){
       if(!this.welcomeForm.word
           ||!this.welcomeForm.period
           ||this.welcomeForm.period.length!==2
       ){
          this.$message.warning("参数不完整");
          return;
       }
       const start_at=dayjs(this.welcomeForm.period[0]).format('HH:mm:ss');
       const end_at=dayjs(this.welcomeForm.period[1]).format('HH:mm:ss');
       const data={
         ...this.welcomeForm,
         start_at,
         end_at
       };
       try {
         this.saveWelcomeWordLoading=true;
         if(this.editWelcomeWord){
           await putWelcomeWordV2(this.group_id,this.careForm.id,this.editWelcomeWord.id,data);
         }else{
           await postWelcomeWordV2(this.group_id,this.careForm.id,data);
         }
         this.$message.success("success");
         this.visibleWelcome=false;
         this.onWelcomeWordList();
       }catch (e) {
         this.$message.error(e.data.message);
       }finally {
         this.saveWelcomeWordLoading=false;

       }

    },
    async saveCareForm(){
      if(!this.careForm.name) {
        this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
        return;
      }
      try {
        this.addCareLoading=true;
        if(this.careForm.id){
          await putCare(this.group_id,this.careForm.id,this.careForm.name,this.careForm.avatar,this.careForm.receive_able);
        }else{
          await postCare(this.group_id,this.careForm.name,this.careForm.avatar,this.careForm.receive_able);
        }
        this.addCareLoading=false;
        this.onClose();
      }catch (e) {
        this.addCareLoading=false;
        if(e.code===code_need_pay||e.code===code_need_renewal){
          this.order_status_result_visible=true;
          this.order_status_title=e.message;
        }
        this.$message.error(e.message);
        throw e;
      }

    },
    onCloseBotForm(){
      this.botFormVisible=false;
    },
    async onEditBot(){
      this.botFormVisible=true;
    },
    onClose(){
      this.$emit("onClose");
    },
    onBeforeUploadImage(file) {
      return true;
    },
    upload(param){
      console.log(param)
      const formData = new FormData()
      formData.append('file[]', param.file)
      // 没有什么参数
      let args={
        'acl':'public-read',
      };
      for(let key  in args){
        formData.append(key,args[key]);
      }
      const sign=this.makeSignature(args,'as2d4f5g6h7j8gfzcvbrgj8')
      formData.append('sign', sign)
      axios.post('https://static.toptopone.com/v2/common/upload',formData).then(rs=>{
        this.careForm.avatar=rs.data.data[0].signed_url;
      }).catch(err=>{
        this.$message.error(this.$t('care.uploadFail'))
      });
    },
  },
  mixins:[orderStateResultMixin,uploadMixin]
}
</script>

<style scoped>

</style>